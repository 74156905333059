export default function Ratios({ data }) {
	if (!data || !data.length) {
		return <div className="text-gray-500">No ratios data available</div>;
	}

	// Get all column headers
	const headers = Object.keys(data[0]).filter((key) => key !== "");

	return (
		<div>
			<h3 className="text-lg font-medium text-gray-900 mb-4">Ratios</h3>
			<p className="text-sm text-gray-500 mb-4">Consolidated Figures</p>

			<div className="overflow-x-auto">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
							>
								Metric
							</th>
							{headers.map((header, index) => (
								<th
									key={index}
									scope="col"
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									{header}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{data.map((row, rowIndex) => (
							<tr
								key={rowIndex}
								className={rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}
							>
								<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
									{row[""] || `Ratio ${rowIndex + 1}`}
								</td>
								{headers.map((header, index) => (
									<td
										key={index}
										className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
									>
										{row[header]}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}
