import { useState } from "react";
import { ArrowUpDown } from "lucide-react";

export default function QuarterlyResults({ data }) {
	console.log("🚀 ~ QuarterlyResults ~ data:", data);
	const [sortConfig, setSortConfig] = useState({
		key: null,
		direction: "ascending",
	});

	if (!data || !data.length) {
		return (
			<div className="text-gray-500 dark:text-gray-400">
				No quarterly results data available
			</div>
		);
	}

	// Get all column headers (quarters)
	const headers = Object.keys(data[0]).filter((key) => key !== "");

	// Sorting function
	const requestSort = (key) => {
		let direction = "ascending";
		if (sortConfig.key === key && sortConfig.direction === "ascending") {
			direction = "descending";
		}
		setSortConfig({ key, direction });
	};

	const sortedData = [...data].sort((a, b) => {
		if (sortConfig.key === null) return 0;

		// Handle sorting for the first column (metric names)
		if (sortConfig.key === "") {
			if (a[""] < b[""]) return sortConfig.direction === "ascending" ? -1 : 1;
			if (a[""] > b[""]) return sortConfig.direction === "ascending" ? 1 : -1;
			return 0;
		}

		// For numeric columns, convert to numbers for sorting
		const aValue = Number.parseFloat(a[sortConfig.key]?.replace(/,/g, "")) || 0;
		const bValue = Number.parseFloat(b[sortConfig.key]?.replace(/,/g, "")) || 0;

		if (aValue < bValue) return sortConfig.direction === "ascending" ? -1 : 1;
		if (aValue > bValue) return sortConfig.direction === "ascending" ? 1 : -1;
		return 0;
	});

	return (
		<div className="space-y-6">
			<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
				<h3 className="text-lg font-medium text-gray-900 dark:text-white">
					Quarterly Results
				</h3>
			</div>

			<p className="text-sm text-gray-500 dark:text-gray-400">
				Consolidated Figures in Rs. Crores
			</p>

			<div className="bg-white dark:bg-gray-800 rounded-lg shadow border border-gray-200 dark:border-gray-700 overflow-hidden">
				<div className="overflow-x-auto">
					<table className="w-full divide-y divide-gray-200 dark:divide-gray-700">
						<thead className="bg-gray-50 dark:bg-gray-700">
							<tr>
								<th
									scope="col"
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
									onClick={() => requestSort("")}
								>
									<div className="flex items-center">
										Metric
										<ArrowUpDown className="w-4 h-4 ml-1" />
									</div>
								</th>
								{headers.map((header, index) => (
									<th
										key={index}
										scope="col"
										className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer"
										onClick={() => requestSort(header)}
									>
										<div className="flex items-center">
											{header}
											<ArrowUpDown className="w-4 h-4 ml-1" />
										</div>
									</th>
								))}
							</tr>
						</thead>
						<tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
							{sortedData.map((row, rowIndex) => (
								<tr
									key={rowIndex}
									className={
										rowIndex % 2 === 0
											? "bg-white dark:bg-gray-800"
											: "bg-gray-50 dark:bg-gray-700"
									}
								>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
										{row[""] || `Row ${rowIndex + 1}`}
									</td>
									{headers.map((header, index) => (
										<td
											key={index}
											className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
										>
											{row[header]}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
