import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import socketIOClient from 'socket.io-client';
import aiftAnalytics from '../analytics/aiftAnalytics';
import Navbar from './Navbar';
import WelcomeHeader from "../components/dashboard/WelcomeHeader";
import AnalyticsHeader from "../components/dashboard/AnalyticsHeader";
import DashboardTable from "../components/dashboard/DashboardTable";
import NewsFeed from "../components/dashboard/NewsFeed";

export default function HomePage() {
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState(null);

  const gotoAiPage = async () => {
    navigate('/ai-assist');
    await aiftAnalytics.actionCall('aiAssistPageFromVisibleTextClicked', 'pageChange');
  };

  const gotoCalculatorsPage = async () => {
    navigate('/calculators');
    await aiftAnalytics.actionCall('calculatorsPageFromVisibleTextClicked', 'pageChange');
  };
  const gotoPortfolioPage = async () => {
    navigate('/portfolio');
    await aiftAnalytics.actionCall('aiAssistPageFromVisibleTextClicked', 'pageChange');
  };

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_AIFT_API_URL);
    socket.emit('userEmail', JSON.parse(localStorage.getItem('userData')).email);
    socket.on('FromAPI', (data) => {
      console.log(data, 'any data');
      setNotificationData(data);
    });

    return () => socket.disconnect();
  }, []);

  return (
    <div className="font-sans">
      <Navbar
        gotoCalculatorsPage={gotoCalculatorsPage}
        gotoAiPage={gotoAiPage}
        notificationData={notificationData}
        gotoPortfolioPage={gotoPortfolioPage}
      />
      <div className="container mx-auto px-4">
        <WelcomeHeader />
        <AnalyticsHeader />
        <DashboardTable />
        <NewsFeed />
      </div>
    </div>
  );
}