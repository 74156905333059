"use client"

import { CheckCircle, Shield, Zap, Clock, Award } from "lucide-react"
import React from "react"

export default function BlackWhiteVideoSection() {
  return (
    <section className="bg-black text-white py-16 px-4 md:px-8">
      <div className="container mx-auto max-w-7xl">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Why Choose AIFT</h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-start">
          {/* Video Column */}
          <div className="w-full" style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: "center"}}>
            <div className="aspect-video w-full rounded-md overflow-hidden border border-gray-800">
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0 }}>
            <iframe
  src="https://www.loom.com/embed/55c6dd12e91e4f848b817c85b4e169d6?sid=47440c86-87b6-4543-8de0-948029ebcc9c&hideEmbedTopBar=true&hide_owner=true&hide_share=true&hide_title=true&hide_branding=true"
  title="Loom Video Embed"
  frameBorder="0"
  allowFullScreen
  style={{
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  }}
></iframe>

</div>

            </div>
          </div>

          {/* Benefits Column */}
          <div className="w-full">
            <h3 className="text-2xl font-bold mb-8 border-b border-gray-800 pb-4">Key Benefits</h3>
            <ul className="space-y-6">
              <li className="flex items-start">
                <div className="mr-4 mt-1">
                  <CheckCircle className="h-6 w-6 text-white" strokeWidth={1.5} />
                </div>
                <div>
                  <h4 className="font-bold text-lg">Advanced AI Technology</h4>
                  <p className="text-gray-300">
                    Our cutting-edge AI algorithms provide unmatched accuracy and performance for your business needs.
                  </p>
                </div>
              </li>

              <li className="flex items-start">
                <div className="mr-4 mt-1">
                  <Shield className="h-6 w-6 text-white" strokeWidth={1.5} />
                </div>
                <div>
                  <h4 className="font-bold text-lg">Enterprise-Grade Security</h4>
                  <p className="text-gray-300">
                    Your data is protected with military-grade encryption and compliance with all industry standards.
                  </p>
                </div>
              </li>

              <li className="flex items-start">
                <div className="mr-4 mt-1">
                  <Zap className="h-6 w-6 text-white" strokeWidth={1.5} />
                </div>
                <div>
                  <h4 className="font-bold text-lg">Lightning-Fast Processing</h4>
                  <p className="text-gray-300">
                    Experience real-time results with our optimized infrastructure designed for speed and efficiency.
                  </p>
                </div>
              </li>

              <li className="flex items-start">
                <div className="mr-4 mt-1">
                  <Clock className="h-6 w-6 text-white" strokeWidth={1.5} />
                </div>
                <div>
                  <h4 className="font-bold text-lg">24/7 Availability</h4>
                  <p className="text-gray-300">
                    Our systems are always on, ensuring your business operations continue without interruption.
                  </p>
                </div>
              </li>

              <li className="flex items-start">
                <div className="mr-4 mt-1">
                  <Award className="h-6 w-6 text-white" strokeWidth={1.5} />
                </div>
                <div>
                  <h4 className="font-bold text-lg">Industry-Leading Support</h4>
                  <p className="text-gray-300">
                    Our expert team is available around the clock to assist with any questions or concerns.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

