import React, { useState } from "react";
import { Menu, X, Calculator, HelpCircle ,Book } from "lucide-react";
import Notifications from "../components/dashboard/Notifications";
import UserProfileDropdown from "../components/profileActions/UserProfileDropdown";

export default function Navbar({
	gotoCalculatorsPage,
	gotoAiPage,
	gotoPortfolioPage,
	notificationData,
}) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<nav className="bg-white shadow-md">
			<div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
				<div className="flex justify-between h-16">
					<div className="flex">
						<div className="flex-shrink-0 flex items-center">
							<img className="h-12 w-auto" style={{borderRadius: "8px"}} src="/icons/logoshadowed.png" alt="Logo" />
						</div>
					</div>

					{/* Desktop menu */}
					<div className="hidden sm:flex sm:items-center sm:ml-6">
						<button
							onClick={gotoCalculatorsPage}
							className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out flex items-center"
						>
							<Calculator className="w-4 h-4 mr-1" />
							Calculators
						</button>
						<button
							onClick={gotoAiPage}
							className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out flex items-center"
						>
							<HelpCircle className="w-4 h-4 mr-1" />
							Ask AI?
						</button>
						<button
							onClick={gotoPortfolioPage}
							className="text-gray-700 hover:text-blue-600 px-3 py-2 rounded-md text-sm font-medium transition duration-150 ease-in-out flex items-center"
						>
							<Book className="w-4 h-4 mr-1" />
							Portfolio
						</button>
						<Notifications notifications={notificationData} />
						<UserProfileDropdown />
					</div>

					{/* Mobile menu button */}
					<div className="flex items-center sm:hidden">
						<Notifications notifications={notificationData} />
						<div className="ml-3 relative">
							<UserProfileDropdown />
						</div>
						<button
							onClick={() => setIsMenuOpen(!isMenuOpen)}
							className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 ml-2"
							aria-expanded="false"
						>
							<span className="sr-only">Open main menu</span>
							{isMenuOpen ? (
								<X className="block h-6 w-6" aria-hidden="true" />
							) : (
								<Menu className="block h-6 w-6" aria-hidden="true" />
							)}
						</button>
					</div>
				</div>
			</div>

			{/* Mobile menu, show/hide based on menu state */}
			<div className={`sm:hidden ${isMenuOpen ? "block" : "hidden"}`}>
				<div className="pt-2 pb-3 space-y-1">
					<button
						onClick={gotoCalculatorsPage}
						className="text-gray-700 hover:text-blue-600 block px-3 py-2 rounded-md text-base font-medium w-full text-left"
					>
						Calculators
					</button>
					<button
						onClick={gotoAiPage}
						className="text-gray-700 hover:text-blue-600 block px-3 py-2 rounded-md text-base font-medium w-full text-left"
					>
						Ask AI?
					</button>
					<button
						onClick={gotoPortfolioPage}
						className="text-gray-700 hover:text-blue-600 block px-3 py-2 rounded-md text-base font-medium w-full text-left"
					>
						Portfolio
					</button>
				</div>
			</div>
		</nav>
	);
}
