"use client"

import { useState, useEffect } from "react"
import { MessageSquare, Send } from "lucide-react"
import server from "../api/Server"

const ChatMessage = ({ message, isUser }) => {
  return (
    <div className={`flex ${isUser ? "justify-end" : "justify-start"} mb-4`}>
      <div className={`max-w-[80%] rounded-lg p-3 ${isUser ? "bg-blue-500 text-white" : "bg-gray-100 text-gray-800"}`}>
        {isUser ? (
          <p>{message.question}</p>
        ) : (
          <div className="space-y-3">
            <p>{message.analysis}</p>
            {message.recommendation && (
              <div className="mt-2 pt-2 border-t border-gray-200 dark:border-gray-700">
                <p className="font-medium text-blue-700 dark:text-blue-300">Recommendation:</p>
                <p>{message.recommendation}</p>
              </div>
            )}
            {message.actionable_steps && message.actionable_steps.length > 0 && (
              <div className="mt-2 pt-2 border-t border-gray-200 dark:border-gray-700">
                <p className="font-medium text-blue-700 dark:text-blue-300">Actionable Steps:</p>
                <ul className="list-disc pl-5 space-y-1 mt-1">
                  {message.actionable_steps.map((step, index) => (
                    <li key={index}>{step}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const ChatInterface = ({ isChatVisible = true, initialMessages ,sessionId}) => {

  const [messages, setMessages] = useState(initialMessages)
  const [inputMessage, setInputMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [sessionActive, setSessionActive] = useState(true)

  // Load messages from localStorage on component mount if none were passed
  useEffect(() => {
    if (initialMessages.length === 0) {
      const storedMessages = localStorage.getItem("chatMessages")
      if (storedMessages) {
        setMessages(JSON.parse(storedMessages))
      }
    }
  }, [initialMessages])

  // Save messages to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("chatMessages", JSON.stringify(messages))
  }, [messages])

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return

    const userMessage = {
      question: inputMessage,
      isUser: true,
    }

    setMessages((prev) => [...prev, userMessage])
    setInputMessage("")
    setIsLoading(true)
    setSessionActive(true)

    try {
      const response = await server.getPortfolioReportAnalysis(sessionId , inputMessage);
      console.log("🚀 ~ handleSendMessage ~ response:", response)
      const data = response.data;

      if (data.error === false && data.code === 200) {
        setMessages((prev) => [
          ...prev,
          {
            ...data.data,
            isUser: false,
          },
        ])
      } else {
        setMessages((prev) => [
          ...prev,
          {
            analysis: "Sorry, I couldn't process your request. Please try again.",
            isUser: false,
          },
        ])
      }
    } catch (error) {
      console.error("Error sending message:", error)
      setMessages((prev) => [
        ...prev,
        {
          analysis: "An error occurred. Please check your connection and try again.",
          isUser: false,
        },
      ])
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  // If chat is not visible but session is active, show session info
  if (!isChatVisible && sessionActive && messages.length > 0) {
    return (
      <div className="flex flex-col h-[calc(100vh-200px)] items-center justify-center bg-gray-50 rounded-lg border-2 border-dashed border-gray-300">
        <MessageSquare className="h-12 w-12 text-gray-400 mb-4" />
        <h3 className="text-lg font-medium text-gray-700">Chat session active</h3>
        <p className="text-gray-500 mt-2">Your conversation is still in progress</p>
        <p className="text-sm text-gray-400 mt-1">{messages.length} messages in this session</p>
      </div>
    )
  }

  return (
    <div className="flex flex-col h-[calc(100vh-200px)]">
      <div className="p-4 border-b">
        <h2 className="text-lg font-semibold">AI Portfolio Assistant</h2>
        <p className="text-sm text-gray-600">
          Ask questions about your portfolio metrics, risk analysis, or recommendations for optimization.
        </p>
      </div>

      <div className="flex-1 overflow-y-auto p-4">
        {messages.length === 0 ? (
          <div className="h-full flex items-center justify-center text-gray-400">
            <div className="text-center">
              <MessageSquare className="h-12 w-12 mx-auto mb-3 opacity-50" />
              <p>Ask a question about your portfolio to get started</p>
              <div className="mt-6 space-y-2">
                <p className="text-sm font-medium text-blue-700">Example questions:</p>
                <button
                  className="block w-full text-left p-2 rounded bg-blue-50 hover:bg-blue-100 text-blue-700 text-sm mb-2"
                  onClick={() => setInputMessage("What is the beta of my portfolio?")}
                >
                  What is the beta of my portfolio?
                </button>
                <button
                  className="block w-full text-left p-2 rounded bg-blue-50 hover:bg-blue-100 text-blue-700 text-sm mb-2"
                  onClick={() => setInputMessage("Which stocks should I trim from my portfolio?")}
                >
                  Which stocks should I trim from my portfolio?
                </button>
                <button
                  className="block w-full text-left p-2 rounded bg-blue-50 hover:bg-blue-100 text-blue-700 text-sm"
                  onClick={() => setInputMessage("How can I reduce my portfolio volatility?")}
                >
                  How can I reduce my portfolio volatility?
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {messages.map((msg, index) => (
              <ChatMessage key={index} message={msg} isUser={msg.isUser} />
            ))}
            {isLoading && (
              <div className="flex justify-start mb-4">
                <div className="bg-gray-100 text-gray-800 rounded-lg p-3 max-w-[80%]">
                  <div className="flex space-x-2">
                    <div className="h-2 w-2 bg-gray-400 rounded-full animate-bounce"></div>
                    <div className="h-2 w-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "0.2s" }}></div>
                    <div className="h-2 w-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: "0.4s" }}></div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="border-t p-4">
        <div className="flex items-center">
          <textarea
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ask about your portfolio..."
            className="flex-1 border border-gray-300 rounded-l-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
            rows={2}
            disabled={isLoading}
          />
          <button
            onClick={handleSendMessage}
            disabled={!inputMessage.trim() || isLoading}
            className={`bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-r-lg h-full ${
              !inputMessage.trim() || isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <Send className="h-5 w-5" />
          </button>
        </div>
        <p className="text-xs text-gray-500 mt-2">Press Enter to send. Shift+Enter for a new line.</p>
      </div>
    </div>
  )
}

export default ChatInterface
