"use client"

const AnalysisTab = ({ title, icon, isActive, isEnabled, onClick }) => {
  return (
    <button
      onClick={onClick}
      disabled={!isEnabled}
      className={`w-full flex items-center p-3 rounded-lg transition-colors ${
        isActive
          ? "bg-blue-50 text-blue-700 border-l-4 border-blue-500"
          : isEnabled
            ? "hover:bg-gray-100 text-gray-700"
            : "opacity-50 cursor-not-allowed text-gray-400"
      }`}
    >
      <span className={`mr-3 ${isActive ? "text-blue-500" : ""}`}>{icon}</span>
      <span className="font-medium">{title}</span>
    </button>
  )
}

export default AnalysisTab

