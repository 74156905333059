import React, { useState, useEffect } from "react";
import Dashboard from "../demo/Dashboard.jsx";
import MetricsCustomizer from "../demo/MetricsCustomizer.jsx";
import AiArenaLayout from "../demo/AiArenaLayout.jsx";
// import { FloatingChat } from "../demo/FloatingChat.jsx";
import server from "../api/Server.js";
import FeedbackWidget from "../demo/FeedbackWidget.jsx";

const DemoPage = () => {
  const [loading, setLoading] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [loaderTextIndex, setLoaderTextIndex] = useState(0);
  const generatingText = [
    "Processing your customized parameters...",
    "Analyzing market data to generate insights...",
    "Evaluating key stock metrics for the best results...",
    "Optimizing your report for maximum accuracy...",
    "Generating insights based on your selected metrics..."
  ];
  const [session_id, setSession_id] = useState(null)
  const [aiResponse, setAiResponse] = useState(null)

  const customizedParameters = (e) => {
    console.log(e)
    handleGenerateInsights(e.additionalContext, e.selectedMetrics, e.symbol);
  };

  const handleGenerateInsights = async (context, metrics, symbol) => {
    try {
      setCurrPage(1);
      setLoading(true);

      let res = await server.generateDemoReport(context, metrics, symbol);
      if (res.data && res.data.aiResponse) {
        try {
            let rawResponse = res.data.aiResponse.trim(); // Remove leading/trailing spaces
    
            // Remove code block markers if present (```json ... ```)
            if (rawResponse.startsWith("```json")) {
                rawResponse = rawResponse.replace(/^```json/, "").trim(); // Remove ```json
            }
            if (rawResponse.endsWith("```")) {
                rawResponse = rawResponse.replace(/```$/, "").trim(); // Remove closing ```
            }
    
            // Attempt to parse cleaned JSON response
            const parsedResponse = JSON.parse(rawResponse);
    
            // Store session ID if available
            if (res.data.session_id) {
                setSession_id(res.data.session_id);
            }
            
    
            // Convert the JSON response into HTML
            let htmlResponse = "";
    
            // Executive Summary
            if (parsedResponse["Executive Summary"]) {
                htmlResponse += `<h2 class="text-lg font-semibold mb-2">Executive Summary</h2>`;
                htmlResponse += `<p class="mb-4">${parsedResponse["Executive Summary"]}</p>`;
            }
    
            // Stock-Specific Insights
            if (parsedResponse["Stock-Specific Insights"]) {
                    const stockData = parsedResponse["Stock-Specific Insights"][symbol];
    
                    htmlResponse += `<h2 class="text-lg font-semibold mt-4 mb-2">Stock Insights</h2>`;
    
                    // Key Metrics
                    if (stockData["Key Metrics"]) {
                        htmlResponse += `<h3 class="text-md font-semibold mt-3">Key Metrics</h3><ul class="list-disc pl-5 mb-4">`;
                        for (const key in stockData["Key Metrics"]) {
                            htmlResponse += `<li><strong>${key}:</strong> ${stockData["Key Metrics"][key]}</li>`;
                        }
                        htmlResponse += `</ul>`;
                    }
    
                    // Opportunities
                    if (stockData["Opportunities"]) {
                        htmlResponse += `<h3 class="text-md font-semibold mt-3">Opportunities</h3>`;
                        htmlResponse += `<p class="mb-4">${stockData["Opportunities"]}</p>`;
                    }
    
                    // Risks
                    if (stockData["Risks"]) {
                        htmlResponse += `<h3 class="text-md font-semibold mt-3">Risks</h3>`;
                        htmlResponse += `<p class="mb-4">${stockData["Risks"]}</p>`;
                    }
    
                    // Recommendations
                    if (stockData["Recommendations"] && stockData["Recommendations"].length > 0) {
                        htmlResponse += `<h3 class="text-md font-semibold mt-3">Recommendations</h3><ul class="list-disc pl-5 mb-4">`;
                        stockData["Recommendations"].forEach((rec) => {
                            htmlResponse += `<li>${rec}</li>`;
                        });
                        htmlResponse += `</ul>`;
                }
            }
    
            // Actionable Next Steps
            if (parsedResponse["Actionable Next Steps"]) {
                htmlResponse += `<h2 class="text-lg font-semibold mt-4 mb-2">Actionable Next Steps</h2><ul class="list-disc pl-5 mb-4">`;
                parsedResponse["Actionable Next Steps"].forEach((step) => {
                    htmlResponse += `<li>${step}</li>`;
                });
                htmlResponse += `</ul>`;
            }
    
            // Store formatted HTML response
            setAiResponse(htmlResponse);
        } catch (error) {
            console.error("Error parsing AI response as JSON:", error);
            setAiResponse("<p class='text-red-500'>Invalid AI response format</p>");
        }
    }    
      setLoading(false)

    } catch (error) {
      setCurrPage(0)
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoaderTextIndex((prevIndex) => (prevIndex + 1) % generatingText.length);
      }, 3000); // Change text every 3 seconds (total rotation takes 15 seconds)

      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [loading, generatingText.length]);

  return (
    <div className="container mx-auto p-4">
      {currPage === 0 ? (
        <>
          <Dashboard />
          <MetricsCustomizer sendToParent={customizedParameters} />
        </>
      ) : (
        <>
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "95vh",
              }}
            >
              <img src="/icons/loader.gif" style={{ height: "100px" }} alt="Loading..." />
              <h3>{generatingText[loaderTextIndex]}</h3>
            </div>
          ) : (
            <AiArenaLayout sessionId={session_id} ai_response={aiResponse} />
          )}
        </>
      )}
      {/* <FloatingChat/> */}
      <FeedbackWidget/>
    </div>
  );
};

export default DemoPage;
