"use client";

import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function Header({ scrollToSection, homeRef, featuresRef, aboutRef, contactRef }) {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setShowNavbar(window.scrollY > 300); // Show navbar after 300px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleBookDemo = () => {
    window.open("https://calendly.com/tanvit-ai-ft/aift-discovery-and-demonstration", "_blank");
  };

  return (
    <motion.header
      className={`fixed top-0 left-1/2 transform -translate-x-1/2 w-[60%] z-50 transition-all duration-300 ${
        showNavbar ? "py-4 opacity-100 shadow-lg" : "opacity-0"
      }`}
      initial={{ opacity: 0 }}
      animate={{ opacity: showNavbar ? 1 : 0 }}
      transition={{ duration: 0.5 }}
      style={{
        padding: "0.5rem 1.5rem",
        borderRadius: "16px",
        marginTop: "1rem",
        backdropFilter: "blur(12px)",
        WebkitBackdropFilter: "blur(12px)",
        background: showNavbar
          ? "linear-gradient(135deg, rgba(0, 0, 0, 0.6), rgba(30, 30, 30, 0.4))"
          : "",
        border: "1px solid rgba(255, 255, 255, 0.2)",
      }}
    >
      <div className="flex justify-between items-center mx-auto">
        {/* Logo */}
        <motion.div
          className="text-2xl font-bold text-white"
          initial={{ opacity: 0 }}
          animate={{ opacity: showNavbar ? 1 : 0 }}
          transition={{ delay: 0.2 }}
        >
          <img src="/icons/logoshadowed.png" style={{ height: "40px" }} alt="AIFT Logo" />
        </motion.div>

        {/* Navbar Links */}
        <nav className="hidden md:block">
          <ul className="flex space-x-8">
            {["Home", "Features", "About", "Contact"].map((item, index) => (
              <motion.li
                key={item}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: showNavbar ? 1 : 0, y: showNavbar ? 0 : -20 }}
                transition={{ delay: 0.1 * (index + 1) }}
              >
                <button
                  className="text-white/80 hover:text-white transition-colors text-md font-medium tracking-wide"
                  onClick={() => {
                    switch (item) {
                      case "Home":
                        scrollToSection(homeRef);
                        break;
                      case "Features":
                        scrollToSection(featuresRef);
                        break;
                      case "About":
                        scrollToSection(aboutRef);
                        break;
                      case "Contact":
                        scrollToSection(contactRef);
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  {item}
                </button>
              </motion.li>
            ))}
          </ul>
        </nav>

        {/* Buttons */}
        <div className="flex items-center space-x-4">
          <motion.button
            className="hidden md:block px-4 py-1 rounded-lg text-white border border-white/30 hover:bg-white hover:text-black transition-all text-sm font-medium shadow-md"
            initial={{ opacity: 0 }}
            animate={{ opacity: showNavbar ? 1 : 0 }}
            transition={{ delay: 0.5 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleLogin}
          >
            Try Free
          </motion.button>

          <motion.button
            className="hidden md:block px-4 py-1 rounded-lg text-black bg-white hover:bg-gray-200 transition-all text-sm font-medium shadow-md"
            initial={{ opacity: 0 }}
            animate={{ opacity: showNavbar ? 1 : 0 }}
            transition={{ delay: 0.6 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleBookDemo}
          >
            Book Demo
          </motion.button>
        </div>
      </div>
    </motion.header>
  );
}
