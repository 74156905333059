"use client";

import { useState, useEffect } from "react";
import { X, Loader2 } from "lucide-react";
import CompanyOverview from "./company-overview";
import QuarterlyResults from "./quarterly-results";
import ProfitLoss from "./profit-loss";
import BalanceSheet from "./balance-sheet";
import Shareholding from "./shareholding";
import Ratios from "./ratios";

export default function CompanyModal({
	isOpen,
	onClose,
	companyData,
	loading,
	error,
	stock,
}) {
	const [activeTab, setActiveTab] = useState("overview");
	const [animateIn, setAnimateIn] = useState(false);

	useEffect(() => {
		if (isOpen) {
			setAnimateIn(true);
		} else {
			setAnimateIn(false);
		}
	}, [isOpen]);

	if (!isOpen) return null;

	const tabs = [
		{ id: "overview", label: "Overview" },
		{ id: "quarterly", label: "Quarterly Results" },
		{ id: "profitloss", label: "Profit & Loss" },
		{ id: "balancesheet", label: "Balance Sheet" },
		// { id: "peers", label: "Peer Comparison" },
		{ id: "shareholding", label: "Shareholding" },
		{ id: "ratios", label: "Ratios" },
	];

	const handleClose = () => {
		setAnimateIn(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const renderTabContent = () => {
		if (loading) {
			return (
				<div className="flex justify-center items-center h-64">
					<Loader2 className="w-12 h-12 text-blue-500 animate-spin" />
				</div>
			);
		}

		if (error) {
			return (
				<div className="flex flex-col items-center justify-center h-64">
					<div className="text-red-500 text-lg mb-2">
						Error loading company data
					</div>
					<div className="text-gray-600">{error}</div>
				</div>
			);
		}

		if (!companyData) {
			return (
				<div className="flex justify-center items-center h-64">
					<div className="text-gray-500">No company data available</div>
				</div>
			);
		}

		switch (activeTab) {
			case "overview":
				return <CompanyOverview company={companyData} />;
			case "quarterly":
				return <QuarterlyResults data={companyData.quarterlyResults} />;
			case "profitloss":
				return <ProfitLoss data={companyData.profitLoss} />;
			case "balancesheet":
				return <BalanceSheet data={companyData.balanceSheet} />;
			// case "peers":
			// 	return <PeerComparison data={companyData.peers} />;
			case "shareholding":
				return <Shareholding data={companyData.shareholding} />;
			case "ratios":
				return <Ratios data={companyData.ratios} />;
			default:
				return <CompanyOverview company={companyData} />;
		}
	};

	return (
		<div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50 transition-opacity duration-300 ease-in-out">
			<div
				className={`bg-white dark:bg-gray-900 rounded-xl w-full max-w-6xl max-h-[90vh] overflow-hidden flex flex-col shadow-2xl transition-all duration-300 ease-in-out ${
					animateIn ? "opacity-100 scale-100" : "opacity-0 scale-95"
				}`}
			>
				<div className="p-6 border-b border-gray-200 dark:border-gray-800 bg-gradient-to-r from-gray-50 to-white dark:from-gray-900 dark:to-gray-800">
					<div className="flex justify-between items-center">
						<h2 className="text-2xl font-bold text-gray-800 dark:text-white">
							{loading
								? "Loading..."
								: companyData?.name ||
								  stock?.["Stock Name"] ||
								  "Company Details"}
						</h2>
						<button
							onClick={handleClose}
							className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white transition-colors p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800"
							aria-label="Close"
						>
							<X className="w-6 h-6" />
						</button>
					</div>
				</div>

				{/* {!loading && companyData && (
					<div className="px-6 py-4 border-b border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-900">
						<CompanyHeader company={companyData} />
					</div>
				)} */}

				<div className="px-6 py-4 border-b border-gray-200 dark:border-gray-800 ">
					<div className="flex space-x-1">
						{tabs.map((tab) => (
							<button
								key={tab.id}
								onClick={() => setActiveTab(tab.id)}
								className={`px-4 py-2 text-sm font-medium rounded-md transition-colors ${
									activeTab === tab.id
										? "bg-blue-600 text-white"
										: "bg-white text-gray-700 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700"
								}`}
							>
								{tab.label}
							</button>
						))}
					</div>
				</div>

				<div className="flex-1 p-6 overflow-y-auto bg-white dark:bg-gray-900">
					{renderTabContent()}
				</div>
			</div>
		</div>
	);
}
