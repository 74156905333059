import { useState } from "react";
import { motion } from "framer-motion";
import { X, MessageCircle } from "lucide-react";

export default function FeedbackWidget() {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (!phone || !message) {
      alert("Phone and Message are required!");
      return;
    }

    const whatsappMessage = `Name: ${email || "N/A"}%0A📞 Phone: ${phone}%0A📝 Query: ${message}`;
    const whatsappNumber = "+919328777219"; // Replace with your number or WhatsApp group link
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`;

    window.open(whatsappLink, "_blank"); // Redirect to WhatsApp
    setIsOpen(false);
    setEmail("");
    setPhone("");
    setMessage("");
  };

  return (
    <div className="fixed bottom-6 right-6 z-50">
      {/* Floating Button */}
      {!isOpen && (
        <motion.button
          className="bg-blue-600 text-white p-4 rounded-full shadow-lg hover:bg-blue-700 transition-all flex items-center justify-center"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => setIsOpen(true)}
        >
          <MessageCircle size={24} />
        </motion.button>
      )}

      {/* Feedback Modal */}
      {isOpen && (
        <motion.div
          className="bg-white p-6 rounded-xl shadow-lg w-80 fixed bottom-16 right-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
        >
          {/* Close Button */}
          <button
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            onClick={() => setIsOpen(false)}
          >
            <X size={20} />
          </button>

          <h3 className="text-lg font-semibold text-gray-800 mb-4">Send us a message</h3>

          {/* Form Fields */}
          <input
            type="email"
            placeholder="Your Email (optional)"
            className="w-full p-2 border rounded mb-3 focus:ring focus:ring-blue-200"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="tel"
            placeholder="Phone Number *"
            className="w-full p-2 border rounded mb-3 focus:ring focus:ring-blue-200"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />

          <textarea
            placeholder="Your Message *"
            className="w-full p-2 border rounded mb-3 focus:ring focus:ring-blue-200"
            required
            rows="3"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>

          {/* Submit Button */}
          <button
            className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition-all"
            onClick={handleSubmit}
          >
            Send on WhatsApp
          </button>
        </motion.div>
      )}
    </div>
  );
}
