import React, { useState, useContext } from "react";
import { AuthContext } from "../AuthContext.js";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Server from "../api/Server";
import LoginForm from "../components/auth/LoginForm.jsx";
import SignupForm from "../components/auth/SignupForm.jsx";
import GoogleLoginButton from "../components/auth/GoogleLoginButton.jsx";

export default function LoginSignupPage() {
	const { login } = useContext(AuthContext);
	const [currPage, setCurrPage] = useState(1);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const handleGoogleLoginSuccess = async (response) => {
		const tokenId = response.credential;
		try {
			const data = await Server.googleLogin(tokenId);
			if (data.data.code === 200) {
				login(data.data.data);
			} else {
				console.error("Login failed:", data.data.msg);
			}
		} catch (error) {
			console.error("Google login error:", error);
		}
	};

	const handleGoogleLoginFailure = (error) => {
		console.error("Google login failed:", error);
	};

	const handleLogin = async (creds) => {
		try {
			setLoading(true);
			let resp = await Server.userLogin(creds.email, creds.password);
			if (resp.data.code !== 200) {
				toast.error(resp.data.message);
				setLoading(false);
				return;
			}
			toast.success("Logged In!");
			let userData = resp.data;
			setTimeout(() => {
				setLoading(false);
				login(userData.data);
				navigate("/home");
			}, 1000);
		} catch (error) {
			toast.error(error.message || "An error occurred");
			setLoading(false);
		}
	};

	const handleSignup = async (creds) => {
		try {
			setLoading(true);
			let resp = await Server.newUserRegister(creds.email, creds.password);
			if (resp.data.code !== 200) {
				toast.error(resp.data.message);
				setLoading(false);
				return;
			}
			toast.success("User Registered!");
			setTimeout(() => {
				setLoading(false);
				setCurrPage(1);
			}, 1000);
		} catch (error) {
			toast.error(error.message || "An error occurred");
			setLoading(false);
		}
	};

	return (
		<div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-white to-black" style={{background: "black"}}>
			<div className="w-full max-w-6xl p-3 mx-auto bg-white rounded-2xl shadow-2xl overflow-hidden md:flex">
				<div className="md:w-1/2 bg-gradient-to-br from-black to-white text-white p-12 flex flex-col justify-center items-center transform transition-all duration-500 ease-in-out hover:scale-105"
				style={{background: "black"}}>
					<img
						src="/icons/logoshadowed.png"
						alt="AIFT Logo"
						className="h-24 w-auto mb-8"
					/>
					<h1 className="text-3xl font-bold mb-4">Welcome to AIFT</h1>
					<p className="text-xl mb-8 text-center">
						Revolutionize your financial advisory with AI-powered insights and
						strategies.
					</p>
					<ul className="list-disc list-inside text-lg space-y-2">
						<li>Streamline your workflow</li>
						<li>Gain deeper market insights</li>
						<li>Personalized financial strategies</li>
						<li>Real-time data analysis</li>
					</ul>
				</div>
				<div className="md:w-1/2 p-12">
					<div className="max-w-md mx-auto">
						<h2 className="text-3xl font-extrabold text-gray-900 mb-6 text-center">
							{currPage === 1
								? "Sign in to your account"
								: "Create a new account"}
						</h2>

						{loading ? (
							<div className="flex justify-center items-center h-64">
								<img
									src="/icons/loadingRipple.gif"
									className="h-16 w-16"
									alt="Loading"
								/>
							</div>
						) : (
							<>
								{currPage === 1 ? (
									<LoginForm onLogin={handleLogin} />
								) : (
									<SignupForm onSignup={handleSignup} />
								)}
							</>
						)}

						<div className="flex justify-center space-x-4 mt-6">
							<button
								onClick={() => setCurrPage(1)}
								className={`px-6 py-2 text-sm font-medium rounded-full transition-colors duration-300 ${
									currPage === 1
										? "bg-indigo-600 text-white"
										: "bg-gray-200 text-gray-700 hover:bg-indigo-100"
								}`}
							>
								Login
							</button>
							<button
								onClick={() => setCurrPage(0)}
								className={`px-6 py-2 text-sm font-medium rounded-full transition-colors duration-300 ${
									currPage === 0
										? "bg-purple-600 text-white"
										: "bg-gray-200 text-gray-700 hover:bg-purple-100"
								}`}
							>
								Signup
							</button>
						</div>

						<div className="mt-8">
							<div className="relative">
								<div className="absolute inset-0 flex items-center">
									<div className="w-full border-t border-gray-300"></div>
								</div>
								<div className="relative flex justify-center text-sm">
									<span className="px-2 bg-white text-gray-500">
										Or continue with
									</span>
								</div>
							</div>

							<div className="flex items-center justify-center mt-6">
								<GoogleLoginButton
									onSuccess={handleGoogleLoginSuccess}
									onFailure={handleGoogleLoginFailure}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ToastContainer
				position="top-right"
				autoClose={1000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover={false}
				theme="light"
				transition={Slide}
			/>
		</div>
	);
}
