export default function BalanceSheet({ data }) {
	console.log("🚀 ~ BalanceSheet ~ data:", data);

	if (!data || !data.length) {
		return (
			<div className="text-gray-500 dark:text-gray-400">
				No balance sheet data available
			</div>
		);
	}

	// Get all column headers (years)
	const headers = Object.keys(data[0]).filter((key) => key !== "");

	return (
		<div className="space-y-6">
			<div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
				<h3 className="text-lg font-medium text-gray-900 dark:text-white">
					Balance Sheet
				</h3>
			</div>

			<p className="text-sm text-gray-500 dark:text-gray-400">
				Consolidated Figures in Rs. Crores
			</p>

			<div className="bg-white dark:bg-gray-800 rounded-lg shadow border border-gray-200 dark:border-gray-700 overflow-hidden">
				<div className="overflow-x-auto">
					<table className="w-full divide-y divide-gray-200 dark:divide-gray-700">
						<thead className="bg-gray-50 dark:bg-gray-700">
							<tr>
								<th
									scope="col"
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
								>
									Metric
								</th>
								{headers.map((header, index) => (
									<th
										key={index}
										scope="col"
										className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
									>
										{header}
									</th>
								))}
							</tr>
						</thead>
						<tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
							{data.map((row, rowIndex) => (
								<tr
									key={rowIndex}
									className={
										rowIndex % 2 === 0
											? "bg-white dark:bg-gray-800"
											: "bg-gray-50 dark:bg-gray-700"
									}
								>
									<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
										{row[""] || `Row ${rowIndex + 1}`}
									</td>
									{headers.map((header, index) => (
										<td
											key={index}
											className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300"
										>
											{row[header]}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
