"use client";

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import {
	ArrowUp,
	ArrowDown,
	TrendingUp,
	DollarSign,
	Percent,
	AlertCircle,
} from "lucide-react";

export default function CompanyOverview({ company }) {
	if (!company) return null;

	const sectorComparisonData = [
		{ name: company.name, value: company.pe || 25 },
		{ name: "Sector Avg", value: 22 },
		{ name: "Market Avg", value: 18 },
	];

	const metrics = [
		{
			label: "Market Cap",
			value: company.marketCap
				? `₹${formatNumber(company.marketCap)} Cr`
				: "N/A",
			icon: <DollarSign className="w-5 h-5 text-blue-500" />,
			change: "+2.5%",
			isPositive: true,
		},
		{
			label: "Current Price",
			value: company.currentPrice ? `₹${company.currentPrice}` : "N/A",
			icon: <TrendingUp className="w-5 h-5 text-green-500" />,
			change: company.priceChange ? `${company.priceChange.toFixed(2)}%` : "0%",
			isPositive: company.priceChange >= 0,
		},
		{
			label: "Stock P/E",
			value: company.pe || "N/A",
			icon: <Percent className="w-5 h-5 text-purple-500" />,
			change: "-1.2%",
			isPositive: false,
		},
		{
			label: "ROCE",
			value: company.roce ? `${company.roce.toFixed(2)}%` : "N/A",
			icon: <TrendingUp className="w-5 h-5 text-orange-500" />,
			change: "+0.8%",
			isPositive: true,
		},
	];

	function formatNumber(num) {
		if (!num && num !== 0) return "-";
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return (
		<div className="space-y-6">
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
				{metrics.map((metric, index) => (
					<div
						key={index}
						className="bg-white dark:bg-gray-800 rounded-lg shadow p-6 border border-gray-200 dark:border-gray-700"
					>
						<div className="flex justify-between items-start">
							<div>
								<p className="text-sm text-gray-500 dark:text-gray-400">
									{metric.label}
								</p>
								<p className="text-2xl font-bold mt-1 text-gray-900 dark:text-white">
									{metric.value}
								</p>
							</div>
							<div className="rounded-full p-2 bg-gray-100 dark:bg-gray-700">
								{metric.icon}
							</div>
						</div>
						<div className="mt-4 flex items-center">
							{metric.isPositive ? (
								<ArrowUp className="w-4 h-4 text-green-500 mr-1" />
							) : (
								<ArrowDown className="w-4 h-4 text-red-500 mr-1" />
							)}
							<span
								className={`text-sm ${
									metric.isPositive ? "text-green-500" : "text-red-500"
								}`}
							>
								{metric.change} from previous period
							</span>
						</div>
					</div>
				))}
			</div>

			{/* <div className="bg-white dark:bg-gray-800 rounded-lg shadow border border-gray-200 dark:border-gray-700">
				<div className="p-4 border-b border-gray-200 dark:border-gray-700">
					<div className="flex justify-between items-center">
						<h3 className="text-lg font-semibold text-gray-900 dark:text-white">
							Stock Price History
						</h3>
						<div className="flex space-x-1 bg-gray-100 dark:bg-gray-700 rounded-lg p-1">
							{["1M", "3M", "6M", "1Y", "5Y"].map((range) => (
								<button
									key={range}
									onClick={() => setTimeRange(range)}
									className={`px-3 py-1 text-xs font-medium rounded-md transition-colors ${
										timeRange === range
											? "bg-blue-600 text-white"
											: "text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600"
									}`}
								>
									{range}
								</button>
							))}
						</div>
					</div>
					<p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
						Historical stock price and trading volume
					</p>
				</div>
				<div className="p-4">
					<div className="h-80">
						<ResponsiveContainer width="100%" height="100%">
							<AreaChart
								data={stockPriceData}
								margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
							>
								<defs>
									<linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
										<stop offset="5%" stopColor="#0088FE" stopOpacity={0.8} />
										<stop offset="95%" stopColor="#0088FE" stopOpacity={0} />
									</linearGradient>
								</defs>
								<XAxis dataKey="date" />
								<YAxis />
								<CartesianGrid strokeDasharray="3 3" opacity={0.1} />
								<Tooltip
									contentStyle={{
										backgroundColor: "rgba(255, 255, 255, 0.9)",
										borderRadius: "8px",
										border: "1px solid #e2e8f0",
										boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
									}}
								/>
								<Area
									type="monotone"
									dataKey="price"
									stroke="#0088FE"
									fillOpacity={1}
									fill="url(#colorPrice)"
									name="Price"
								/>
							</AreaChart>
						</ResponsiveContainer>
					</div>
				</div>
			</div> */}

			<div className="grid grid-cols-1 md:grid-cols-2 gap-6">
				<div className="bg-white dark:bg-gray-800 rounded-lg shadow border border-gray-200 dark:border-gray-700">
					<div className="p-4 border-b border-gray-200 dark:border-gray-700">
						<h3 className="text-lg font-semibold text-gray-900 dark:text-white">
							P/E Ratio Comparison
						</h3>
						<p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
							Comparing P/E ratio with sector and market averages
						</p>
					</div>
					<div className="p-4">
						<div className="h-64">
							<ResponsiveContainer width="100%" height="100%">
								<BarChart data={sectorComparisonData}>
									<CartesianGrid strokeDasharray="3 3" opacity={0.1} />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip
										contentStyle={{
											backgroundColor: "rgba(255, 255, 255, 0.9)",
											borderRadius: "8px",
											border: "1px solid #e2e8f0",
											boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
										}}
									/>
									<Bar
										dataKey="value"
										fill="#0088FE"
										radius={[4, 4, 0, 0]}
										name="P/E Ratio"
									/>
								</BarChart>
							</ResponsiveContainer>
						</div>
					</div>
				</div>

				<div className="bg-white dark:bg-gray-800 rounded-lg shadow border border-gray-200 dark:border-gray-700">
					<div className="p-4 border-b border-gray-200 dark:border-gray-700">
						<h3 className="text-lg font-semibold text-gray-900 dark:text-white">
							About {company.name}
						</h3>
						<p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
							Company overview and key information
						</p>
					</div>
					<div className="p-4">
						<div className="space-y-4">
							{company.about ? (
								<p className="text-gray-700 dark:text-gray-300">
									{company.about}
								</p>
							) : (
								<div className="flex items-center text-gray-500 dark:text-gray-400">
									<AlertCircle className="w-5 h-5 mr-2" />
									No company description available
								</div>
							)}

							{company.keyPoints && (
								<div className="mt-4">
									<h4 className="font-medium text-gray-900 dark:text-gray-100 mb-2">
										Key Highlights
									</h4>
									<div className="text-gray-700 dark:text-gray-300">
										{company.keyPoints}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
