import ChatInterface from "./ChatInterface.jsx"

const AiArenaLayout = ({ sessionId, ai_response }) => {
  return (
    <div className="flex bg-gray-100 h-screen" style={{height: "96vh"}}>
      <div className="flex-grow overflow-hidden">
        <ChatInterface session_id={sessionId} aiResponse={ai_response} />
      </div>
    </div>
  )
}

export default AiArenaLayout

