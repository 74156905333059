import { useState } from "react";

export default function Shareholding({ data }) {
	console.log("🚀 ~ Shareholding ~ data:", data);
	const [view, setView] = useState("quarterly");
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [showModal, setShowModal] = useState(false);

	if (!data || !data[view] || !data[view].length) {
		return <div className="text-gray-500">No shareholding data available</div>;
	}

	const viewData = data[view];

	// Get all column headers
	const headers = Object.keys(viewData[0]).filter((key) => key !== "");

	const handleCategoryClick = (category) => {
		setSelectedCategory(category);
		setShowModal(true);
	};

	const Modal = ({ isOpen, onClose, category }) => {
		if (!isOpen) return null;

		return (
			<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
				<div className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4">
					<div className="flex justify-between items-center mb-4">
						<h3 className="text-lg font-medium text-gray-900">
							{category} Shareholders
						</h3>
						<button
							onClick={onClose}
							className="text-gray-400 hover:text-gray-500"
						>
							<svg
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<div className="text-sm text-gray-600">
						<p>
							This section shows detailed information about {category}{" "}
							shareholders.
						</p>
						<p className="mt-2">The data is updated quarterly and includes:</p>
						<ul className="list-disc list-inside mt-2">
							<li>Percentage holdings</li>
							<li>Number of shareholders</li>
							<li>Recent changes</li>
						</ul>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<h3 className="text-lg font-medium text-gray-900 mb-4">
				Shareholding Pattern
			</h3>
			<p className="text-sm text-gray-500 mb-4">Numbers in percentages</p>

			<div className="flex justify-between items-center mb-6">
				<div className="inline-flex rounded-md shadow-sm" role="group">
					<button
						type="button"
						onClick={() => setView("quarterly")}
						className={`px-4 py-2 text-sm font-medium rounded-l-md ${
							view === "quarterly"
								? "bg-blue-600 text-white"
								: "bg-white text-gray-700 hover:bg-gray-50"
						} border border-gray-300`}
					>
						Quarterly
					</button>
					<button
						type="button"
						onClick={() => setView("yearly")}
						className={`px-4 py-2 text-sm font-medium rounded-r-md ${
							view === "yearly"
								? "bg-blue-600 text-white"
								: "bg-white text-gray-700 hover:bg-gray-50"
						} border border-gray-300 border-l-0`}
					>
						Yearly
					</button>
				</div>
			</div>

			<div className="overflow-x-auto">
				<table className="min-w-full divide-y divide-gray-200">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
							>
								Category
							</th>
							{headers.map((header, index) => (
								<th
									key={index}
									scope="col"
									className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
								>
									{header}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{viewData.map((row, rowIndex) => (
							<tr
								key={rowIndex}
								className={rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}
							>
								<td className="px-6 py-4 whitespace-nowrap text-sm">
									<button
										onClick={() =>
											handleCategoryClick(row[""] || `Category ${rowIndex + 1}`)
										}
										className="text-blue-600 hover:text-blue-800 font-medium flex items-center"
									>
										{row[""] || `Category ${rowIndex + 1}`}
									</button>
								</td>
								{headers.map((header, index) => (
									<td
										key={index}
										className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
									>
										{row[header]}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<Modal
				isOpen={showModal}
				onClose={() => setShowModal(false)}
				category={selectedCategory}
			/>

			<p className="text-sm text-gray-500 mt-4">
				* The classifications might have changed from Sep'2022 onwards.
				<span
					className="ml-1 text-blue-500 cursor-help"
					title="The new XBRL format added more details from Sep'22 onwards. Classifications such as banks and foreign portfolio investors were not available earlier. The sudden changes in FII or DII can be because of these changes. Click on the line-items to see the names of individual entities."
				>
					ⓘ
				</span>
			</p>
		</div>
	);
}
