import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../api/Server";
import aiftAnalytics from "../../analytics/aiftAnalytics";
import { PlusCircle, RefreshCw, AlertCircle, X } from "lucide-react";

import GenerateAiReport from "../ai/GenerateAiReport";
import CompanyModal from "../screener/company-modal";

export default function DashboardTable() {
	const navigate = useNavigate();
	const [userPortfolioData] = useState([
		{
			"Stock Name": "ZOMATO",
			"Stocks Bought": 3,
			"Price Per Stock": 257,
			"Total Purchase Price": 771,
			"Purchase Date": "21-03-2025",
			"Trade Fees": 10,
		},
		{
			"Stock Name": "RELIANCE",
			"Stocks Bought": 2,
			"Price Per Stock": 1210,
			"Total Purchase Price": 2420,
			"Purchase Date": "16-03-2025",
			"Trade Fees": 20,
		},
		{
			"Stock Name": "IREDA",
			"Stocks Bought": 4,
			"Price Per Stock": 150,
			"Total Purchase Price": 600,
			"Purchase Date": "29-03-2025",
			"Trade Fees": 15,
		},
		{
			"Stock Name": "MOBIKWIK",
			"Stocks Bought": 1,
			"Price Per Stock": 354,
			"Total Purchase Price": 354,
			"Purchase Date": "28-03-2025",
			"Trade Fees": 30,
		},
		{
			"Stock Name": "SUZLON",
			"Stocks Bought": 4,
			"Price Per Stock": 55,
			"Total Purchase Price": 220,
			"Purchase Date": "27-03-2025",
			"Trade Fees": 25,
		},
		{
			"Stock Name": "HDFCBANK",
			"Stocks Bought": 3,
			"Price Per Stock": 1721,
			"Total Purchase Price": 5163,
			"Purchase Date": "15-03-2025",
			"Trade Fees": 15,
		},
		{
			"Stock Name": "ITCHOTELS",
			"Stocks Bought": 3,
			"Price Per Stock": 195,
			"Total Purchase Price": 585,
			"Purchase Date": "19-03-2025",
			"Trade Fees": 10,
		},
		{
			"Stock Name": "SWIGGY",
			"Stocks Bought": 4,
			"Price Per Stock": 337,
			"Total Purchase Price": 1348,
			"Purchase Date": "18-03-2025",
			"Trade Fees": 18,
		},
		{
			"Stock Name": "PAYTM",
			"Stocks Bought": 2,
			"Price Per Stock": 665,
			"Total Purchase Price": 1330,
			"Purchase Date": "31-03-2025",
			"Trade Fees": 12,
		},
	]);
	const [columns] = useState([
		{ key: "Stock Name", value: "Stock Name", width: "18%" },
		{ key: "Stocks Bought", value: "Stocks Bought", width: "18%" },
		{ key: "Price Per Stock", value: "Price Per Stock", width: "18%" },
		{ key: "Purchase Date", value: "Purchase Date", width: "18%" },
		{ key: "Trade Fees", value: "Trade Fees", width: "18%" },
	]);
	const [openAiReportModal, setOpenAiReportModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedStock, setSelectedStock] = useState(null);
	const [companyData, setCompanyData] = useState(null);
	const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
	const [companyLoading, setCompanyLoading] = useState(false);
	const [companyError, setCompanyError] = useState(null);

	const handleGotoManageDashboard = () => {
		navigate("/manage-portfolio");
	};

	const handleOpenAiReportModal = async () => {
		setOpenAiReportModal(true);
		await aiftAnalytics.actionCall("reportModalOpenButtonClicked", "ai");
	};

	const fetchCompanyData = async (symbol) => {
		console.log("🚀 ~ fetchCompanyData ~ symbol:", symbol);
		setCompanyLoading(true);
		setCompanyError(null);

		try {
			const response = await server.getCompanyDataFromScreener(symbol);
			console.log("🚀 ~ fetchCompanyData ~ response:", response);

			if (!response) {
				throw new Error("Company not found or error fetching data");
			}

			// Parse the HTML to extract company data
			const parser = new DOMParser();
			const doc = parser.parseFromString(response, "text/html");

			// Extract company header information from the correct structure
			const companyName =
				doc.querySelector("h1.margin-0")?.textContent.trim() || symbol;

			// Extract current price and price change
			const priceContainer = doc.querySelector(".font-size-18.strong");
			let currentPrice = "";
			let priceChange = 0;
			let lastUpdated = "";

			if (priceContainer) {
				// Get current price
				const priceElement = priceContainer.querySelector("span");
				if (priceElement) {
					currentPrice = priceElement.textContent
						.trim()
						.replace("₹", "")
						.replace(/,/g, "")
						.trim();
				}

				// Get price change
				const priceChangeElement = priceContainer.querySelector(".down, .up");
				if (priceChangeElement) {
					const changeText = priceChangeElement.textContent.trim();
					priceChange = parseFloat(changeText.replace("%", ""));
				}

				// Get last updated date
				const lastUpdatedElement = priceContainer.nextElementSibling;
				if (lastUpdatedElement) {
					lastUpdated = lastUpdatedElement.textContent
						.trim()
						.replace("- close price", "")
						.trim();
				}
			}

			// Extract company ratios from the top-ratios list
			let marketCap = "";
			let pe = "";
			let roce = "";
			let roe = "";
			let bookValue = "";
			let dividendYield = "";
			let faceValue = "";
			let highPrice = "";
			let lowPrice = "";

			const ratioItems = doc.querySelectorAll("#top-ratios li");
			ratioItems.forEach((item) => {
				const name = item.querySelector(".name")?.textContent.trim() || "";
				const valueElement = item.querySelector(".value");
				if (!valueElement) return;

				const numberElement = valueElement.querySelector(".number");
				if (!numberElement) return;

				const value = numberElement.textContent.trim().replace(/,/g, "");

				switch (name) {
					case "Market Cap":
						marketCap = parseFloat(value);
						break;
					case "Stock P/E":
						pe = parseFloat(value);
						break;
					case "ROCE":
						roce = parseFloat(value);
						break;
					case "ROE":
						roe = parseFloat(value);
						break;
					case "Book Value":
						bookValue = parseFloat(value);
						break;
					case "Dividend Yield":
						dividendYield = parseFloat(value);
						break;
					case "Face Value":
						faceValue = parseFloat(value);
						break;
					case "High / Low":
						const [high, low] = value
							.split("/")
							.map((v) => parseFloat(v.trim()));
						highPrice = high;
						lowPrice = low;
						break;
					default:
						// Skip any unknown ratio names
						break;
				}
			});

			// Extract table data with improved selector
			const extractTableData = (selector) => {
				const table = doc.querySelector(selector);
				if (!table) return [];

				const headers = Array.from(table.querySelectorAll("th")).map((th) =>
					th.textContent.trim()
				);
				const rows = Array.from(table.querySelectorAll("tbody tr"));

				return rows.map((row) => {
					const cells = Array.from(row.querySelectorAll("td"));
					const rowData = {};

					cells.forEach((cell, index) => {
						if (index < headers.length) {
							rowData[headers[index] || ""] = cell.textContent.trim();
						}
					});

					return rowData;
				});
			};

			// Extract data from different sections
			const quarterlyData = extractTableData("#quarters table");
			const profitLossData = extractTableData("#profit-loss table");
			const balanceSheetData = extractTableData("#balance-sheet table");

			// Try multiple selectors for peers section
			let peerData = extractTableData("#peers");
			if (!peerData || peerData.length === 0) {
				peerData = extractTableData(".peers");
			}
			if (!peerData || peerData.length === 0) {
				const peersSection = doc.querySelector('section[data-section="peers"]');
				if (peersSection) {
					peerData = extractTableData(peersSection.querySelector("table"));
				}
			}

			const shareholdingData = {
				quarterly: extractTableData("#shareholding #quarterly-shp"),
				yearly: extractTableData("#shareholding #yearly-shp"),
			};
			const ratiosData = extractTableData("#ratios");

			// Extract website, BSE, NSE info
			let website = "";
			let bse = "";
			let nse = "";

			const links = doc.querySelectorAll("a");
			links.forEach((link) => {
				const href = link.getAttribute("href") || "";
				const text = link.textContent.trim();

				if (href.includes("http") && !href.includes("screener.in")) {
					website = text;
				}
				if (href.includes("BSE")) {
					bse = text.match(/\d+/)?.[0] || "";
				}
				if (href.includes("NSE")) {
					nse = text.match(/[A-Z]+/)?.[0] || "";
				}
			});

			// Extract about text
			const aboutElement = Array.from(doc.querySelectorAll("p")).find(
				(p) => p.textContent.length > 100 && !p.querySelector("a")
			);
			const about = aboutElement ? aboutElement.textContent.trim() : "";

			// Extract key points
			const keyPointsElement =
				doc.querySelector(".key-points") ||
				doc.querySelector(".company-profile");
			const keyPoints = keyPointsElement
				? keyPointsElement.textContent.trim()
				: "";

			// Construct the company data object
			const data = {
				name: companyName,
				website,
				bse,
				nse,
				currentPrice: parseFloat(currentPrice),
				priceChange,
				lastUpdated,
				marketCap,
				pe,
				roce,
				roe,
				bookValue,
				dividendYield,
				faceValue,
				highPrice,
				lowPrice,
				about,
				keyPoints,
				quarterlyResults: quarterlyData,
				profitLoss: profitLossData,
				balanceSheet: balanceSheetData,
				peers: peerData || [],
				shareholding: shareholdingData,
				ratios: ratiosData,
			};

			console.log("Extracted Company Data:", data);
			setCompanyData(data);
		} catch (err) {
			console.error("Error fetching company data:", err);
			setCompanyError(err.message);
		} finally {
			setCompanyLoading(false);
		}
	};

	const handleRowClick = (stock) => {
		setSelectedStock(stock);
		// Assuming the stock name or symbol is in the 'Stock Name' field
		const symbol = stock["Stock Name"] || stock.stockName;
		if (symbol) {
			fetchCompanyData(symbol);
			setIsCompanyModalOpen(true);
		}
	};

	useEffect(() => {
		setLoading(false);
	}, []);

	return (
		<div className="container mx-auto px-4 py-8">
			<div className="bg-white rounded-xl shadow-lg p-6">
				<div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
					<h2 className="text-xl md:text-2xl font-bold text-gray-800">
						Connected Data
					</h2>
					<button
						onClick={
							userPortfolioData.length === 0 ? null : handleOpenAiReportModal
						}
						className={`w-full md:w-auto px-4 py-2 rounded-lg transition-colors duration-300 flex items-center justify-center ${
							userPortfolioData.length === 0
								? "bg-gray-300 text-gray-400 cursor-not-allowed"
								: "bg-blue-600 text-white hover:bg-blue-700"
						}`}
						disabled={userPortfolioData.length === 0}
					>
						<AlertCircle className="w-5 h-5 mr-2" />
						<span className="text-sm md:text-base">Generate AI Report</span>
					</button>
				</div>
				{userPortfolioData.length > 0 && (
					<div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-center">
						<div className="flex-grow">
							<h3 className="text-lg font-semibold text-blue-800 mb-2">
								Generate Your Personalized Financial Report
							</h3>
							<p className="text-blue-600">
								Click the "Generate AI Report" button to create a customized
								analysis of your portfolio data.
							</p>
						</div>
					</div>
				)}

				{loading ? (
					<div className="flex justify-center items-center h-64">
						<RefreshCw className="w-12 h-12 text-blue-500 animate-spin" />
					</div>
				) : !userPortfolioData || userPortfolioData.length === 0 ? (
					<div className="flex flex-col items-center justify-center h-64">
						<img src="/icons/noData.png" className="h-20 mb-4" alt="No data" />
						<h3 className="text-xl font-semibold text-gray-700 mb-4">
							No data to show here
						</h3>
						<button
							onClick={handleGotoManageDashboard}
							className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
						>
							<PlusCircle className="w-5 h-5 mr-2" />
							Add Data
						</button>
					</div>
				) : (
					<div className="overflow-x-auto">
						<table className="w-full mb-8">
							<thead>
								<tr>
									{columns.map((col, idx) => (
										<th
											key={idx}
											className="px-4 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
										>
											{col.value}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{userPortfolioData.map((data, index) => (
									<tr
										key={index}
										className={`${
											index % 2 === 0 ? "bg-white" : "bg-gray-50"
										} cursor-pointer hover:bg-gray-100`}
										onClick={() => handleRowClick(data)}
									>
										{columns.map((col, idx) => (
											<td
												key={idx}
												className="px-4 py-2 whitespace-nowrap text-sm text-gray-700"
											>
												{data[col.key]}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>

			{openAiReportModal && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
					<div className="bg-white rounded-lg w-full max-w-4xl max-h-[80vh] overflow-auto">
						<div className="p-6">
							<div className="flex justify-between items-center mb-4">
								<h2 className="text-2xl font-bold text-gray-800">
									Generate Your Financial Report
								</h2>
								<button onClick={() => setOpenAiReportModal(false)}>
									<X className="w-6 h-6" />
								</button>
							</div>
							<GenerateAiReport portfolioData={userPortfolioData} />
						</div>
					</div>
				</div>
			)}

			{isCompanyModalOpen && (
				<CompanyModal
					isOpen={isCompanyModalOpen}
					onClose={() => setIsCompanyModalOpen(false)}
					companyData={companyData}
					loading={companyLoading}
					error={companyError}
					stock={selectedStock}
				/>
			)}
		</div>
	);
}
