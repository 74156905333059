import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis
} from 'recharts';
import {
  TrendingUp,
  Calculator,
  ArrowUpRight,
  ArrowDownRight,
  BookOpen,
  PieChart as PieChartIcon,
  Activity
} from 'lucide-react';

// Helper function to clean and parse JSON strings with code block markers
const parseJSONField = (rawString) => {
  if (typeof rawString !== "string") {
    console.warn("parseJSONField received non-string input:", rawString);
    return rawString; // Return as-is if it's already an object
  }

  let cleaned = rawString.trim();
  if (cleaned.startsWith("```json")) {
    cleaned = cleaned.replace(/^```json/, "").trim();
  }
  if (cleaned.endsWith("```")) {
    cleaned = cleaned.replace(/```$/, "").trim();
  }
  
  try {
    return JSON.parse(cleaned);
  } catch (error) {
    console.error("JSON Parsing Error:", error, "Input:", cleaned);
    return null;
  }
};

const PortfolioAnalysisVisualization = ({ portfolioMetrics, analysisResponse }) => {
  console.log("🚀 ~ PortfolioAnalysisVisualization ~ analysisResponse:", analysisResponse)
  const [activeTab, setActiveTab] = useState('summary');
  const [portfolioAnalysis, setPortfolioAnalysis] = useState(null);

  // Parse the analysisResponse fields on mount
  useEffect(() => {
    if (analysisResponse && analysisResponse.data) {
      const parsedAnalysis = {
        high_level_portfolio_summary: parseJSONField(analysisResponse.data.high_level_portfolio_summary),
        rebalancing_analysis: parseJSONField(analysisResponse.data.rebalancing_analysis),
        actionable_next_steps: parseJSONField(analysisResponse.data.actionable_next_steps),
        explanation_for_calculation_content: parseJSONField(analysisResponse.data.explanation_for_calculation_content)
      };
      console.log("🚀 ~ useEffect ~ parsedAnalysis:", parsedAnalysis)
      setPortfolioAnalysis(parsedAnalysis);
    }
  }, [analysisResponse]);

  // Wait for parsed data
  if (!portfolioAnalysis) return <div>Loading...</div>;

  // Parse metric data for charts
  const riskMetrics = [
    { name: 'Beta', value: portfolioMetrics.data.metrics.portfolioBeta },
    { name: 'Volatility', value: portfolioMetrics.data.metrics.portfolioVolatility },
    { name: 'Sharpe Ratio', value: portfolioMetrics.data.metrics.sharpeRatio },
    { name: 'Sortino Ratio', value: portfolioMetrics.data.metrics.sortinoRatio },
    { name: 'Calmar Ratio', value: portfolioMetrics.data.metrics.calmarRatio },
    { name: 'Treynor Ratio', value: portfolioMetrics.data.metrics.treynorRatio },
    { name: 'Max Drawdown', value: portfolioMetrics.data.metrics.maxDrawdown }
  ];

  const currentAllocation = [
    { name: 'KO', value: portfolioMetrics.data.metrics.weights.KO },
    { name: 'PG', value: portfolioMetrics.data.metrics.weights.PG },
    { name: 'JNJ', value: portfolioMetrics.data.metrics.weights.JNJ },
    { name: 'WMT', value: portfolioMetrics.data.metrics.weights.WMT },
    { name: 'AAPL', value: portfolioMetrics.data.metrics.weights.AAPL },
    { name: 'AMZN', value: portfolioMetrics.data.metrics.weights.AMZN },
    { name: 'META', value: portfolioMetrics.data.metrics.weights.META },
    { name: 'MSFT', value: portfolioMetrics.data.metrics.weights.MSFT },
    { name: 'TSLA', value: portfolioMetrics.data.metrics.weights.TSLA },
    { name: 'GOOGL', value: portfolioMetrics.data.metrics.weights.GOOGL }
  ];

  // Format adjustment data from high_level_portfolio_summary
  const trimData = Object.entries(portfolioAnalysis.high_level_portfolio_summary.asset_adjustments.trim).map(([name, value]) => ({
    name,
    value: parseFloat(value.replace('%', ''))
  }));

  const increaseData = Object.entries(portfolioAnalysis.high_level_portfolio_summary.asset_adjustments.increase).map(([name, value]) => ({
    name,
    value: parseFloat(value.replace('%', ''))
  }));

  // Create formatted formula data for display from explanation_for_calculation_content
  const formulaData = Object.entries(portfolioAnalysis.explanation_for_calculation_content.metric_explanations).map(([key, value]) => {
    console.log("🚀 ~ formulaData ~ value:", value)

    // Assumes that the string contains "formula:" and "explanation:" markers
    const formulaSplit = value.split('formula:');
    let formulaPart = '';
    let explanationPart = '';
    if (formulaSplit.length > 1) {
      // Split at "explanation:" if available
      const parts = formulaSplit[1].split('explanation:');
      formulaPart = parts[0].split('...')[0].trim();
      explanationPart = parts[1] ? parts[1].trim() : '';
    }
    
    return {
      name: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
      formula: formulaPart,
      explanation: explanationPart
    };
  });
  console.log("🚀 ~ formulaData ~ formulaData:", formulaData)

  // Colors for charts
  const COLORS = [
    "#0088FE", "#00C49F", "#FFBB28", "#FF8042", 
    "#8884d8", "#82ca9d", "#ffc658", "#8dd1e1", 
    "#a4de6c", "#d0ed57"
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="mb-6 bg-white rounded-lg shadow p-6">
          <h1 className="text-2xl font-bold text-gray-900 flex items-center">
            <TrendingUp className="mr-2 text-blue-600" size={24} />
            Portfolio Analysis Dashboard
          </h1>
          <p className="text-gray-600 mt-2">Comprehensive analysis and recommendations for your investment portfolio</p>
        </div>

        {/* Navigation Tabs */}
        <div className="mb-6 bg-white rounded-lg shadow overflow-hidden">
          <div className="flex border-b">
            <button 
              onClick={() => setActiveTab('summary')}
              className={`flex-1 py-4 px-6 text-center font-medium ${activeTab === 'summary' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
            >
              Executive Summary
            </button>
            <button 
              onClick={() => setActiveTab('allocation')}
              className={`flex-1 py-4 px-6 text-center font-medium ${activeTab === 'allocation' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
            >
              Allocation Analysis
            </button>
            <button 
              onClick={() => setActiveTab('rebalancing')}
              className={`flex-1 py-4 px-6 text-center font-medium ${activeTab === 'rebalancing' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
            >
              Rebalancing
            </button>
            <button 
              onClick={() => setActiveTab('formulas')}
              className={`flex-1 py-4 px-6 text-center font-medium ${activeTab === 'formulas' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500 hover:text-gray-700'}`}
            >
              Formulas
            </button>
          </div>
        </div>

        {/* Executive Summary Tab */}
        {activeTab === 'summary' && (
          <div className="space-y-6">
            {/* Summary Card */}
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
                <BookOpen className="mr-2 text-blue-600" size={20} />
                Executive Summary
              </h2>
              <p className="text-gray-700 mb-6">{portfolioAnalysis.high_level_portfolio_summary.executive_summary}</p>
              
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Risk Analysis</h3>
              <p className="text-gray-700 mb-6">{portfolioAnalysis.high_level_portfolio_summary.risk_analysis}</p>
              
              {/* Risk Metrics Visualization */}
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Key Risk Metrics</h3>
              <div className="h-80 mb-6">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={riskMetrics} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" width={100} />
                    <Tooltip formatter={(value) => value.toFixed(4)} />
                    <Bar 
                      dataKey="value" 
                      fill="#8884d8"
                      barSize={30}
                      background={{ fill: '#eee' }}
                      isAnimationActive={true}
                    >
                      {riskMetrics.map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`} 
                          fill={entry.value < 0 ? '#FF8042' : '#0088FE'} 
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>

              {/* Risk Metrics Radar Chart */}
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Risk Metrics Radar</h3>
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <RadarChart outerRadius={120} data={riskMetrics.filter(m => m.name !== 'Max Drawdown')}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="name" />
                    <PolarRadiusAxis angle={30} domain={[-3, 3]} />
                    <Radar 
                      name="Portfolio" 
                      dataKey="value" 
                      stroke="#8884d8" 
                      fill="#8884d8" 
                      fillOpacity={0.6} 
                    />
                    <Tooltip formatter={(value) => value.toFixed(4)} />
                  </RadarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        )}

        {/* Portfolio Allocation Tab */}
        {activeTab === 'allocation' && (
          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
                <PieChartIcon className="mr-2 text-blue-600" size={20} />
                Current Asset Allocation
              </h2>
              
              <div className="h-80 mb-8">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={currentAllocation}
                      cx="50%"
                      cy="50%"
                      labelLine={true}
                      label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(1)}%`}
                      outerRadius={120}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {currentAllocation.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>

              <h2 className="text-xl font-bold text-gray-900 mb-4">Stock Overview</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(portfolioAnalysis.high_level_portfolio_summary.stock_overview).map(([ticker, description]) => (
                  <div key={ticker} className="bg-gray-50 rounded-lg p-4">
                    <h5 className="text-lg font-medium text-gray-900 mb-1">{ticker}</h5>
                    <p className="text-gray-700">{description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Rebalancing/Recommendations Tab */}
        {activeTab === 'rebalancing' && (
          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
                <Activity className="mr-2 text-blue-600" size={20} />
                Recommended Adjustments
              </h2>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                {/* Assets to Trim */}
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-3 flex items-center">
                    <ArrowDownRight className="h-5 w-5 text-red-500 mr-2" />
                    Assets to Trim
                  </h3>
                  <div className="h-60">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={trimData}
                        layout="vertical"
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" unit="%" />
                        <YAxis dataKey="name" type="category" width={80} />
                        <Tooltip formatter={(value) => `${value}%`} />
                        <Bar dataKey="value" fill="#FF8042" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                {/* Assets to Increase */}
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 mb-3 flex items-center">
                    <ArrowUpRight className="h-5 w-5 text-green-500 mr-2" />
                    Assets to Increase
                  </h3>
                  <div className="h-60">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={increaseData}
                        layout="vertical"
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" unit="%" />
                        <YAxis dataKey="name" type="category" width={80} />
                        <Tooltip formatter={(value) => `${value}%`} />
                        <Bar dataKey="value" fill="#00C49F" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              <h2 className="text-xl font-bold text-gray-900 mb-4">Actionable Next Steps</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-blue-50 rounded-lg p-4">
                  <h3 className="text-md font-semibold text-blue-800 mb-2">Short-Term Recommendations</h3>
                  <p className="text-gray-700">{portfolioAnalysis.actionable_next_steps.short_term_recommendations}</p>
                </div>
                <div className="bg-purple-50 rounded-lg p-4">
                  <h3 className="text-md font-semibold text-purple-800 mb-2">Long-Term Strategies</h3>
                  <p className="text-gray-700">{portfolioAnalysis.actionable_next_steps.long_term_strategies}</p>
                </div>
                <div className="bg-amber-50 rounded-lg p-4">
                  <h3 className="text-md font-semibold text-amber-800 mb-2">Hedging Opportunities</h3>
                  <p className="text-gray-700">{portfolioAnalysis.actionable_next_steps.hedging_opportunities}</p>
                </div>
                <div className="bg-teal-50 rounded-lg p-4">
                  <h3 className="text-md font-semibold text-teal-800 mb-2">Alternative Investments</h3>
                  <p className="text-gray-700">{portfolioAnalysis.actionable_next_steps.alternative_investments}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Formulas Tab */}
        {activeTab === 'formulas' && (
          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
                <Calculator className="mr-2 text-blue-600" size={20} />
                Formulas & Calculations
              </h2>
              <div className="grid grid-cols-1 gap-4">
                {formulaData.map((item, index) => (
                  <div key={index} className="border rounded-lg p-4">
                    <h3 className="text-lg font-semibold text-gray-900 mb-2">{item.name}</h3>
                    <div className="bg-gray-50 rounded p-3 font-mono text-sm mb-3 overflow-x-auto">
                      {item.formula}
                    </div><br/>
                    <p className="text-gray-700">{item.explanation}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PortfolioAnalysisVisualization;
