"use client"

import { useState, useRef, useEffect } from "react"
import { Send, Paperclip, Bot, User, X, ArrowLeft, MessageSquare } from "lucide-react"
import server from "../api/Server"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function ChatInterface({ session_id, aiResponse }) {
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState("")
  const [isUploading, setIsUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const [isProcessing, setIsProcessing] = useState(false)
  const [sessionId, setSessionId] = useState(null)
  const [isChatClosed, setIsChatClosed] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const chatContainerRef = useRef(null)

  useEffect(() => {
    setSessionId(session_id);

    setMessages(prevMessages => [...prevMessages, { type: 'ai', content: aiResponse }]);
  }, [session_id, aiResponse]);

  const handleSend = async () => {
    if (isProcessing || input === "" || isChatClosed) {
      return
    }

    if (input.trim()) {
      setMessages([...messages, { type: "user", content: input }])
      setInput("")
      setIsProcessing(true)

      try {
        const response = await server.generateChatResponse(input, sessionId)
        console.log("🚀 ~ handleSend ~ input:", input)

        if (response && response.data && response.data.analysis) {
          const { analysis, recommendation, actionable_steps } = response.data

          const formattedResponse = `
            <div>
              <p class="mt-2 text-gray-200"><strong>📊 Analysis:</strong> ${analysis}</p>
              <p class="mt-2 text-gray-200"><strong>🔍 Recommendation:</strong> ${recommendation}</p>
              <ul class="mt-2 text-gray-200 list-disc list-inside">
                ${actionable_steps.map((step) => `<li>✅ ${step}</li>`).join("")}
              </ul>
            </div>
          `

          const aiMessage = { type: "ai", content: formattedResponse }
          setMessages((prev) => [...prev, aiMessage])
        } else {
          const errorMessage = { type: "ai", content: response.message }
          setMessages((prev) => [...prev, errorMessage])
        }
      } catch (error) {
        console.error("Error communicating with the AI:", error)
        setIsChatClosed(true)
      } finally {
        setIsProcessing(false)
      }
    }
  }

  const handleFileUpload = (event) => {
    if (isChatClosed) return
    const file = event.target.files[0]
    if (file) {
      setIsUploading(true)
      let progress = 0
      const interval = setInterval(() => {
        progress += 10
        setUploadProgress(progress)
        if (progress >= 100) {
          clearInterval(interval)
          setIsUploading(false)
          setUploadProgress(0)
          setMessages((prev) => [...prev, { type: "user", content: `File uploaded: ${file.name}` }])
        }
      }, 500)
    }
  }

  return (
    <div className="flex flex-col lg:flex-row w-full h-full">
      {/* AI Insights Section */}
      <div
        className={`w-full lg:w-2/5 bg-gray-800 bg-opacity-50 text-white rounded-lg shadow-xl overflow-hidden transition-all duration-300 ease-in-out ${showChat ? "hidden lg:block" : "h-[calc(100%-4rem)] lg:h-full"}`}
      style={{background: "black"}}>
        <div className="p-4 lg:p-6 bg-blue-600 bg-opacity-20">
          <h2 className="text-xl lg:text-2xl font-bold text-blue-400">🚀 Portfolio Insights</h2>
        </div>
        <div className="p-4 lg:p-6 overflow-y-auto h-full custom-scrollbar">
          <div style={{height: "90vh"}} dangerouslySetInnerHTML={{ __html: aiResponse }} />
        </div>
      </div>

      {/* Chat Interface Section */}
      <div
        className={`w-full lg:w-3/5 flex flex-col bg-gray-800 bg-opacity-50 text-white rounded-lg shadow-xl overflow-hidden transition-all duration-300 ease-in-out ${showChat ? "h-full" : "h-16 lg:h-full"}`}
      style={{background: "black"}}>
        {showChat && (
          <div className="p-4 bg-gray-900 flex items-center lg:hidden">
            <button onClick={() => setShowChat(false)} className="text-white">
              <ArrowLeft size={24} />
            </button>
            <h2 className="ml-4 text-xl font-bold">Chat</h2>
          </div>
        )}
        <div
          ref={chatContainerRef}
          className={`flex-1 overflow-y-auto p-4 lg:p-6 space-y-4 custom-scrollbar ${showChat ? "block" : "hidden lg:block"}`}
        >
          {messages.length === 0 ? (
            <div className="flex items-center justify-center h-full">
              <p className="text-gray-400 text-center">Chat with our AI about your portfolio analysis</p>
            </div>
          ) : (
            messages.map((message, index) => (
              <div key={index} className={`flex ${message.type === "user" ? "justify-end" : "justify-start"}`}>
                <div
                  className={`max-w-[85%] lg:max-w-[70%] rounded-lg p-3 lg:p-4 ${
                    message.type === "user" ? "bg-blue-600" : "bg-gray-700"
                  } flex items-start space-x-2 lg:space-x-3 shadow-md`}
                >
                  {message.type === "user" ? (
                    <User className="w-5 h-5 lg:w-6 lg:h-6 mt-1 text-white flex-shrink-0" />
                  ) : (
                    <Bot className="w-5 h-5 lg:w-6 lg:h-6 mt-1 text-blue-400 flex-shrink-0" />
                  )}
                  <div
                    className="flex-grow text-sm lg:text-base"
                    dangerouslySetInnerHTML={{ __html: message.content }}
                  />
                </div>
              </div>
            ))
          )}
          {isProcessing && (
            <div className="flex justify-start">
              <div className="bg-gray-700 rounded-lg p-3 lg:p-4 flex items-center space-x-2 lg:space-x-3 shadow-md">
                <Bot className="w-5 h-5 lg:w-6 lg:h-6 text-blue-400 flex-shrink-0" />
                <div className="animate-pulse text-sm lg:text-base">AI is processing your request...</div>
              </div>
            </div>
          )}
          {isChatClosed && (
            <div className="flex justify-center">
              <div className="bg-red-600 rounded-lg p-3 lg:p-4 flex items-center space-x-2 lg:space-x-3 shadow-md">
                <X className="w-5 h-5 lg:w-6 lg:h-6 text-white flex-shrink-0" />
                <div className="text-white text-sm lg:text-base">Chat is closed. You can't send more messages.</div>
              </div>
            </div>
          )}
        </div>
        <div className={`p-4 lg:p-6 bg-gray-900 bg-opacity-50 ${showChat ? "block" : "hidden lg:block"}`}>
          {isUploading && (
            <div className="mb-4">
              <div className="h-2 bg-gray-700 rounded-full">
                <div
                  className="h-2 bg-blue-500 rounded-full transition-all duration-300 ease-in-out"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            </div>
          )}
          <div className="flex items-center space-x-2 lg:space-x-4">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={isChatClosed ? "Chat is closed" : "Type your message..."}
              className="flex-1 bg-gray-700 text-white rounded-full px-4 lg:px-6 py-2 lg:py-3 text-sm lg:text-base focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300"
              onKeyDown={(e) => e.key === "Enter" && handleSend()}
              disabled={isChatClosed}
            />
            <label className={`cursor-pointer ${isChatClosed ? "opacity-50 cursor-not-allowed" : ""}`}>
              <Paperclip className="text-gray-400 hover:text-white transition-colors w-5 h-5 lg:w-6 lg:h-6" />
              <input type="file" className="hidden" onChange={handleFileUpload} disabled={isChatClosed} />
            </label>
            <button
              onClick={handleSend}
              className={`bg-blue-500 hover:bg-blue-600 rounded-full p-2 lg:p-3 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300 ${
                isChatClosed ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isChatClosed}
            >
              <Send size={20} className="lg:w-6 lg:h-6" />
            </button>
          </div>
        </div>
        {!showChat && (
          <button
            onClick={() => setShowChat(true)}
            className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white rounded-full p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300 lg:hidden"
          >
            <MessageSquare size={24} />
          </button>
        )}
      </div>
      <ToastContainer position="top-right" />
    </div>
  )
}

