"use client"

import React from "react"

import { useRef } from "react"
import { motion, useScroll, useTransform, type MotionValue } from "framer-motion"
import { useInView } from "react-intersection-observer"

interface FeatureStepProps {
  title: string
  description: string
  icon: React.ReactNode
  index: number
}

const FeatureStep = ({ title, description, icon, index }: FeatureStepProps) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.2,
  })

  return (
    <motion.div
      ref={ref}
      className="flex gap-6 items-start"
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.6, delay: index * 0.1 }}
    >
      <motion.div
        className="flex-shrink-0 w-12 h-12 rounded-full bg-white text-black flex items-center justify-center text-xl font-bold"
        whileHover={{ scale: 1.1, backgroundColor: "#f0f0f0" }}
        style={{zIndex: "9"}}
      >
        {icon}
      </motion.div>

      <div className="space-y-2">
        <h3 className="text-xl font-bold text-white">{title}</h3>
        <p className="text-white/70">{description}</p>
      </div>
    </motion.div>
  )
}

interface FeaturesProps {
  scrollYProgress: MotionValue<number>
}

export default function Features({ scrollYProgress }: FeaturesProps) {
  const featuresRef = useRef<HTMLDivElement>(null)

  const { scrollYProgress: featuresScrollProgress } = useScroll({
    target: featuresRef,
    offset: ["start end", "end start"],
  })

  const lineHeight = useTransform(featuresScrollProgress, [0, 1], ["0%", "100%"])

  const steps = [
    {
      title: "Upload Portfolio",
      description: "Securely upload your investment data through our intuitive interface.",
      icon: "1",
    },
    {
      title: "Preprocessing",
      description: "AIFT cleans and structures your data for accurate analysis.",
      icon: "2",
    },
    {
      title: "Metric Calculation",
      description: "Key financial metrics like Sharpe ratio, beta, and volatility are computed.",
      icon: "3",
    },
    {
      title: "Rebalancing Algorithms",
      description: "AI-driven optimization adjusts stock weightings for optimal performance.",
      icon: "4",
    },
    {
      title: "Final Report & Decision-Making",
      description: "Receive actionable insights to make informed investment decisions.",
      icon: "5",
    },
  ]

  return (
    <section ref={featuresRef} className="py-24 bg-black" id="features">
      <div className="container mx-auto px-4">
        <motion.div
          className="text-center mb-16 max-w-3xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-white">How AIFT Works</h2>
          <p className="text-white/70 text-lg">
            Our AI-powered platform transforms your investment strategy through a sophisticated process
          </p>
        </motion.div>

        <div className="relative max-w-3xl mx-auto">
          {/* Animated line connecting the steps */}
          <div className="absolute left-6 top-0 bottom-0 w-0.5 bg-white/10">
            <motion.div className="absolute top-0 left-0 w-full bg-white" style={{ height: lineHeight }} />
          </div>

          <div className="space-y-16">
            {steps.map((step, index) => (
              <FeatureStep
                key={index}
                title={step.title}
                description={step.description}
                icon={step.icon}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

