import { useState } from "react";
import { ChevronDownIcon } from "lucide-react";

const faqs = [
	{
		question: "What pricing model does AI-FT offer?",
		answer:"Our flexible subscription model scales with your business needs. Choose from tiered plans based on assets under management, number of portfolios, or API usage. Enterprise clients benefit from custom pricing tailored to specific requirements. Contact our sales team for a personalized quote and complimentary consultation.",
	},
	{
		question:
			"How does AI-FT safeguard client data?",
		answer:
			"Your clients' data security is our top priority. AI-FT employs bank-grade encryption, SOC 2 compliance protocols, and regular security audits. Data is anonymized during processing, and our zero-knowledge architecture ensures that sensitive information remains under your control. We maintain strict data segregation and adhere to GDPR, CCPA, and financial industry regulatory standards.",
	},
	{
		question: "How accurate are AI-FT's portfolio calculations and recommendations?",
		answer:
			"AI-FT's algorithms consistently achieve 98.7% accuracy in backtesting against historical data. Our recommendations undergo rigorous validation through Monte Carlo simulations and stress testing across multiple market conditions. The platform continuously self-calibrates based on real-world performance metrics, with transparent accuracy reporting available in your dashboard.",
	},
	{
		question: "Will AI-FT replace portfolio and asset managers?",
		answer:
			"No—AI-FT is designed to enhance, not replace, human expertise. Our platform serves as your intelligent co-pilot, automating time-consuming analysis and identifying opportunities that might otherwise be missed. This allows portfolio managers to focus on client relationships, strategic decision-making, and the nuanced aspects of wealth management that require human judgment and empathy.",
	},
	{
		question: "What optimisation approaches does AI-FT use for portfolios?",
		answer:
			"AI-FT employs multiple optimization strategies, including modern portfolio theory, factor-based analysis, and machine learning algorithms that adapt to changing market conditions. Our proprietary Adaptive Risk Framework™ continuously balances risk-adjusted returns while considering tax implications, liquidity requirements, and client-specific constraints. The platform also offers customizable optimization parameters to align with your investment philosophy",
	},
	{
		question: "What additional essential services does AI-FT provide?",
		answer:
			"Beyond core portfolio optimization, AI-FT offers integrated client reporting, tax-loss harvesting automation, ESG screening, custom constraint modeling, and seamless integration with major custodians and financial data providers. Our Premium tier includes white-labeled client portals and dedicated API access for custom integrations with your existing systems. All tiers include regular feature updates and priority technical support.",
	},
];

export default function Component() {
	return (
		<div className="bg-gray-50 py-12 sm:py-16 lg:py-20">
			<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div className="mx-auto max-w-4xl text-center">
					<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						FAQs
					</h1>
					<p className="mt-4 text-lg text-gray-500">
						Discover how AI can transform your business
					</p>
				</div>
				<div className="mt-12">
					<dl className="space-y-6 divide-y divide-gray-200">
						{faqs.map((faq, index) => (
							<FAQItem
								key={index}
								question={faq.question}
								answer={faq.answer}
							/>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}

function FAQItem({ question, answer }) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="pt-6">
			<dt className="text-lg">
				<button
					onClick={() => setIsOpen(!isOpen)}
					className="flex w-full items-start justify-between text-left text-gray-400"
					aria-expanded={isOpen}
				>
					<span className="font-medium text-gray-900">{question}</span>
					<span className="ml-6 flex h-7 items-center">
						<ChevronDownIcon
							className={`${
								isOpen ? "rotate-180" : ""
							} h-6 w-6 transform transition-transform duration-200`}
							aria-hidden="true"
						/>
					</span>
				</button>
			</dt>
			{isOpen && (
				<dd className="mt-2 pr-12">
					<p className="text-base text-gray-500">{answer}</p>
				</dd>
			)}
		</div>
	);
}
