import React, { useCallback, useEffect, useState } from "react";
import AddStockDataModal from "./AddStockDataModal";
import server from "../../api/Server";
import { ToastContainer, toast } from "react-toastify";
import aiftAnalytics from "../../analytics/aiftAnalytics";
import { PlusCircle, Edit2, Trash2, RefreshCw, X } from "lucide-react";

export default function PortfolioData() {
  const [showAddStockModal, setShowAddStockModal] = useState(false);
  const [type, setType] = useState("manualStockAdd");
  const [toEditData, setToEditData] = useState(null);
  const [userPortfolioData, setUserPortfolioData] = useState([]);
  const [columns, setColumns] = useState([
    { key: "stockName", value: "Stock Name", width: "13%" },
    { key: "stocksBought", value: "Stocks Bought", width: "13%" },
    { key: "pricePerStock", value: "Price Per Stock", width: "13%" },
    { key: "purchasedDate", value: "Purchased Date", width: "13%" },
    { key: "tradeFees", value: "Trade Fees", width: "13%" },
    { key: "action", value: "Actions", width: "13%" },
  ]);
  const [loading, setLoading] = useState(false);

  const handleClose = async (data) => {
    setShowAddStockModal(false);
    if (data.data.portfolioType === "manualStockAdd") {
      if (data.data.portfolioData && data.data.portfolioData.length > 0) {
        setUserPortfolioData(data.data.portfolioData);
        setLoading(false);
      } else {
        setUserPortfolioData([]);
        setLoading(false);
      }
    } else {
      getUserData();
    }
  };

  const numericDateToYyyyMmDd = (numericDate) => {
    const baseDate = new Date(Date.UTC(1900, 0, 1));
    const excelOffset = numericDate - 1;
    const actualDate = new Date(baseDate.getTime() + excelOffset * 24 * 60 * 60 * 1000);
    const year = actualDate.getUTCFullYear();
    const month = String(actualDate.getUTCMonth() + 1).padStart(2, "0");
    const day = String(actualDate.getUTCDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const handleDeleteManualStock = async (index) => {
    setLoading(true);
    try {
      let resp = await server.deleteManualStock(index);
      if (resp.data.code !== 200) {
        toast(resp.data.msg);
        setLoading(false);
        return;
      }
      toast(resp.data.msg);
      await aiftAnalytics.actionCall('manualStockDeleted', 'portfolioData');
      handleClose(resp.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleEditManualStock = (idx) => {
    setShowAddStockModal(true);
    setToEditData({ ...userPortfolioData[idx], idx });
  };

  const handleShowAddStockModal = async (val) => {
    setShowAddStockModal(val);
    await aiftAnalytics.actionCall('addStockDataButtonClicked', 'portfolioData');
  };

  const getUserData = useCallback(async () => {
    setLoading(true);
    let data = await server.getUserPortfolio();
    console.log(data);
    if (data.data && data.data.code === 200 && data.data.data[0].portfolio_data) {
      let portfolioData = data.data.data[0].portfolio_data.map((item) => {
        let newItem = { ...item };
        for (let key in newItem) {
          if (
            typeof newItem[key] === "number" &&
            newItem[key] > 40000 &&
            key.toLowerCase().includes("date")
          ) {
            newItem[key] = numericDateToYyyyMmDd(newItem[key]);
          }
        }
        return newItem;
      });

      setUserPortfolioData(portfolioData);

      if (data.data.data[0].portfolio_type === "excelSheetData") {
        setType("excelSheetData");
        let cols = Object.keys(portfolioData[0]).map((key) => ({
          key: key,
          value: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1"),
          width: "10%",
        }));
        setColumns(cols);
      }
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-end mb-4">
        <button
          onClick={() => handleShowAddStockModal(true)}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
        >
          <PlusCircle className="w-5 h-5 mr-2" />
          Add stock data
        </button>
      </div>

      <div className="bg-white rounded-xl shadow-lg p-6">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <RefreshCw className="w-12 h-12 text-blue-500 animate-spin" />
          </div>
        ) : userPortfolioData.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-64">
            <img src="/icons/noData.png" className="h-20 mb-4" alt="No data" />
            <h3 className="text-xl font-semibold text-gray-700 mb-4">No data to show here</h3>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr>
                  {columns.map((col, idx) => (
                    <th key={idx} className="px-4 py-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                      {col.value}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {userPortfolioData.map((data, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                    {columns.map((col, idx) => (
                      <td key={idx} className="px-4 py-2 whitespace-nowrap text-sm text-gray-700">
                        {col.key === "action" ? (
                          <div className="flex space-x-2">
                            <button
                              onClick={() => handleEditManualStock(index)}
                              className="p-1 rounded-full hover:bg-gray-200 transition-colors duration-300"
                            >
                              <Edit2 className="w-4 h-4 text-blue-600" />
                            </button>
                            <button
                              onClick={() => handleDeleteManualStock(index)}
                              className="p-1 rounded-full hover:bg-gray-200 transition-colors duration-300"
                            >
                              <Trash2 className="w-4 h-4 text-red-600" />
                            </button>
                          </div>
                        ) : (
                          data[col.key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {showAddStockModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg w-full max-w-2xl">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-800">Add Stock Data</h2>
                <button onClick={() => setShowAddStockModal(false)} className="text-gray-400 hover:text-gray-600">
                  <X className="w-6 h-6" />
                </button>
              </div>
              <AddStockDataModal
                toEdit={toEditData}
                stockType={type}
                close={handleClose}
              />
            </div>
          </div>
        </div>
      )}

      <ToastContainer position="top-right" />
    </div>
  );
}