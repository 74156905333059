import React, { useEffect, useState, useRef } from 'react';
import { BarChart2 } from 'lucide-react';
import axios from 'axios';
import FinanceMetrics from './FinanceMetrics';
export default function Dashboard() {
  
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [combinedData, setCombinedData] = useState([]);
  const [loading, setLoading]= useState(false);

  const isFetched = useRef(false);


  const getData = async () => {
    try {
      setLoading(true);
      const stocksSymbols = ['AAPL', 'GOOGL', 'MSFT', 'AMZN', 'TSLA', 'META', 'KO', 'WMT', 'JNJ', 'PG'];   
      const quotePromises = stocksSymbols.map((symbol, idx) =>
        axios
          .get(`https://finnhub.io/api/v1/quote?symbol=${symbol}&token=${process.env.REACT_APP_FINNHUB_API_URL}`)
          .then((response) => ({ id: idx + 1, symbol, ...response.data }))
      );
      const metricPromises = stocksSymbols.map((symbol, idx) =>
        axios
          .get(`https://finnhub.io/api/v1/stock/metric?symbol=${symbol}&metric=all&token=${process.env.REACT_APP_FINNHUB_API_URL}`)
          .then((response) => ({ id: idx + 1, symbol, metrics: response.data }))
      );

      const quotes = await Promise.all(quotePromises);
      const metrics = await Promise.all(metricPromises);

      // Combine data
      const combined = quotes.map((quoteData) => {
        const metricData = metrics.find((metric) => metric.symbol === quoteData.symbol);
        return {
          id: quoteData.id,
          symbol: quoteData.symbol,
          quote: quoteData,
          metrics: metricData ? metricData.metrics?.metric : null,
        };
      });

      // Update state
      setCombinedData(combined);

      console.log("Combined Data:", combined);
    } catch (error) {
      console.log("Error fetching data:", error);
      setLoading(false);
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isFetched.current) {
      getData();
      isFetched.current = true;
    }
  }, []);
  const LoadingDots = () => (
    <div className="flex justify-center items-center space-x-1">
      <span className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.2s]"></span>
      <span className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.1s]"></span>
      <span className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-bounce"></span>
    </div>
  );
  const SkeletonRow = () => (
     
    <tr className="border-b bg-gray-50 hover:bg-blue-50 transition duration-150 ease-in-out">
      {[...Array(9)].map((_, index) => (
        <td key={index} className="px-6 py-4">
          <div className="w-full h-4 bg-gray-300 rounded-md animate-pulse"></div>
        </td>
      ))}
    </tr>
  )

  return (
    <div className=" p-8">
    <h1 className="text-4xl font-extrabold mb-2 text-gray-800 text-center">
      Manage Your <span className="text-blue-500">Stock Portfolio</span> Efficiently
    </h1>
    <p className="text-xl text-gray-600 text-center" style={{marginBottom: "1rem"}}>
      Track and manage your investments with real-time data and insights.
    </p>
      <div className="bg-white shadow-2xl rounded-2xl overflow-hidden">
        <div className="overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-white uppercase bg-gradient-to-r from-black to-blue-800">
              <tr>
                {['Stock', 'Open', 'High', 'Low', 'Close', 'PE Ratio', 'Net Profit Margin', 'Beta', 'Actions'].map((column) => (
                  <th key={column} className={`px-6 py-4 font-semibold ${column==="Actions" && "text-center "}`} >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>

            {loading
                ? [...Array(10)].map((_, index) => <SkeletonRow key={index} />)
              : combinedData.map((stock, index) => (
                <tr key={stock.id} className={`border-b ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-blue-50 transition duration-150 ease-in-out`}>
                  <td className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap transition-all duration-500 
                  ${loading ? "text-transparent relative" : "text-black"}`}>
                  {loading ? (
                    <div className="w-16 h-4 bg-gray-300 rounded-md animate-pulse"></div>
                  ) : (
                    stock.symbol
                  )}
                </td>

                <td className={`px-6 py-4 transition-all duration-500 ${loading ? "text-transparent relative" : "text-black"}`}>
                  {loading ? <LoadingDots /> : `$${stock.quote.o?.toFixed(2)}`}
                </td>

                <td className={`px-6 py-4 transition-all duration-500 ${loading ? "text-transparent relative" : "text-black"}`}>
                  {loading ? <LoadingDots /> : `$${stock.quote.h?.toFixed(2)}`}
                </td>

                <td className={`px-6 py-4 transition-all duration-500 ${loading ? "text-transparent relative" : "text-black"}`}>
                  {loading ? <LoadingDots /> : `$${stock.quote.l?.toFixed(2)}`}
                </td>

                <td className={`px-6 py-4 transition-all duration-500 ${loading ? "text-transparent relative" : "text-black"}`}>
                  {loading ? <LoadingDots /> : `$${stock.quote.c?.toFixed(2)}`}
                </td>

                <td className={`px-6 py-4 transition-all duration-500 ${loading ? "text-transparent relative" : "text-black"}`}>
                  {loading ? <LoadingDots /> : stock.metrics?.peTTM?.toFixed(2) || 'N/A'}
                </td>

                <td className={`px-6 py-4 transition-all duration-500 ${loading ? "text-transparent relative" : "text-black"}`}>
                  {loading ? <LoadingDots /> : `${(stock.metrics?.netProfitMarginTTM)?.toFixed(2) || 'N/A'}%`}
                </td>

                <td className={`px-6 py-4 transition-all duration-500 ${loading ? "text-transparent relative" : "text-black"}`}>
                  {loading ? <LoadingDots /> : stock.metrics?.beta?.toFixed(2) || 'N/A'}
                </td>

                  <td className="px-6 py-4 text-right flex justify-center">
                    <button
                      onClick={() => setSelectedCompany(stock)}
                      className="font-medium text-blue-600 hover:text-blue-800 flex items-center transition duration-150 ease-in-out transform hover:scale-105"
                    >
                      <BarChart2 size={16} className="mr-2" />
                      View Metrics
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    {selectedCompany && (
      <FinanceMetrics 
        company={selectedCompany} 
        onClose={() => setSelectedCompany(null)} 
      />
    )}
  </div>
  );
}




