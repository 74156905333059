import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	DefaulterRebalancer,
	PortfolioAnalysis,
	PortfolioMetrics,
	Reblancer,
} from "../pages/data";
const apiUrl = process.env.REACT_APP_AIFT_API_URL;

class Server {
	async newUserRegister(email, password) {
		try {
			return await axios.post(`${apiUrl}/user/create`, {
				email: email,
				password: password,
			});
		} catch (error) {
			console.log(error);
		}
	}
	async userLogin(email, password) {
		try {
			return await axios.post(`${apiUrl}/user/login`, {
				email: email,
				password: password,
			});
		} catch (error) {
			console.log(error);
		}
	}
	async googleLogin(tokenId) {
		try {
			return await axios.post(`${apiUrl}/user/google-login`, {
				tokenId,
			});
		} catch (error) {}
	}
	async userLogout() {
		try {
			return await axios.post(`${apiUrl}/user/logout`, {});
		} catch (error) {
			console.log(error);
		}
	}
	async createManualStock(data) {
		try {
			return await axios.post(`${apiUrl}/portfolio/create-manual-stock`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
				portfolioData: data,
			});
		} catch (error) {}
	}
	async updateManualStock(data, id) {
		try {
			return await axios.post(`${apiUrl}/portfolio/update-manual-stock`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
				portfolioData: data,
				id: id,
			});
		} catch (error) {}
	}
	async createStocksFromFile(data) {
		console.log(data);
		try {
			return await axios.post(`${apiUrl}/portfolio/integrate-excel-data`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
				formData: data,
			});
		} catch (error) {}
	}
	async getUserPortfolio() {
		try {
			return await axios.post(`${apiUrl}/portfolio/get`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
			});
		} catch (error) {}
	}
	async deleteManualStock(idx) {
		try {
			return await axios.post(`${apiUrl}/portfolio/delete-manual-stock`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
				idx: idx,
			});
		} catch (error) {}
	}
	async getDashboardDetails() {
		try {
			return await axios.post(`${apiUrl}/portfolio/get-dashboard-details`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
			});
		} catch (error) {}
	}
	async generateAiReport(prompt, count) {
		try {
			return await axios.post(`${apiUrl}/ai/generate-financial-report`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
				prompt,
				regenerate: count === 0 ? false : true,
			});
		} catch (error) {}
	}

	async generateStreamDataFromGemini(obj) {
		try {
			return await axios.post(`${apiUrl}/ai/generate-stream-data-from-gemini`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
				promptData: obj,
			});
		} catch (error) {}
	}
	async saveNotificationDetails(stockName, operator, value) {
		try {
			return await axios.post(`${apiUrl}/notify/save-notification-details`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
				stockName,
				operator,
				value,
			});
		} catch (error) {}
	}

	async getNotificationSettingsByStock(stockName) {
		try {
			return await axios.post(
				`${apiUrl}/notify/get-notification-settings-by-stock`,
				{
					email: JSON.parse(localStorage.getItem("userData")).email,
					stockName,
				}
			);
		} catch (error) {}
	}

	async deleteNotificationSettingsByStock(stockName, operator, value) {
		try {
			return await axios.post(
				`${apiUrl}/notify/delete-notification-settings-by-stock`,
				{
					email: JSON.parse(localStorage.getItem("userData")).email,
					stockName,
					operator,
					value,
				}
			);
		} catch (error) {}
	}
	async generateDemoReport(context, metrics, symbol) {
		try {
			let res = await axios.post(`${apiUrl}/ai/generate-demo-report`, {
				symbol,
				metrics,
				context,
			});
			if (res.data && res.data.code === 200) {
				return res.data;
			}
		} catch (error) {
			return { error: true, message: "unexpected error occured" };
		}
	}
	async generateChatResponse(message, sessionId) {
		try {
			const response = await axios.post(`${apiUrl}/ai/generate-chat-response`, {
				userMessage: message,
				sessionId: sessionId,
			});

			if (response.data) {
				return response.data; // Return the expected response format
			} else {
				return {
					error: true,
					message: "Unexpected response format from server",
				};
			}
		} catch (error) {
			console.error("Error generating chat response:", error);
			if (
				error.response &&
				error.response.data &&
				error.response.data.code === 429
			) {
				toast.error(
					"Rate limit of 5 request per one hour is hit. Please try after sometime"
				); // Show error toast
			}
		}
	}
	async getNewsSentiments() {
		try {
			return await axios.post(`${apiUrl}/alphavantage/get-news-sentiment`, {});
		} catch (error) {}
	}
	async getTopGainers() {
		try {
			return await axios.post(`${apiUrl}/alphavantage/get-top-gainers`, {});
		} catch (error) {}
	}
	async getTopLosers() {
		try {
			return await axios.post(`${apiUrl}/alphavantage/get-top-losers`, {});
		} catch (error) {}
	}
	async getGraphData() {
		try {
			return await axios.post(`${apiUrl}/alphavantage/get-graph-data`, {});
		} catch (error) {}
	}

	async getPortfolioMetrics() {
		const email = JSON.parse(localStorage.getItem("userData"))?.email;

		if (email === "anubhavladha11@gmail.com") {
			return { data: PortfolioMetrics }; // Return data from `data.js`
		}

		try {
			return await axios.post(`${apiUrl}/portfolio/get-portfolio-metrics`, {
				email,
			});
		} catch (error) {
			console.error(error);
		}
	}

	async getDefaulterRebalancingPortfolio() {
		const email = JSON.parse(localStorage.getItem("userData"))?.email;

		if (email === "anubhavladha11@gmail.com") {
			return { data: DefaulterRebalancer };
		}

		try {
			return await axios.post(
				`${apiUrl}/portfolio/rebalance-portfolio-defaulter-analysis`,
				{ email }
			);
		} catch (error) {
			console.error(error);
		}
	}

	async getTargetRebalancingPortfolio() {
		const email = JSON.parse(localStorage.getItem("userData"))?.email;

		if (email === "anubhavladha11@gmail.com") {
			return { data: Reblancer };
		}

		try {
			return await axios.post(
				`${apiUrl}/portfolio/rebalance-portfolio-target-analysis`,
				{ email }
			);
		} catch (error) {
			console.error(error);
		}
	}

	async getPortfolioAnalysis() {
		const email = JSON.parse(localStorage.getItem("userData"))?.email;

		if (email === "anubhavladha11@gmail.com") {
			return { data: PortfolioAnalysis };
		}

		try {
			return await axios.post(`${apiUrl}/portfolio/portfolio-analysis`, {
				email,
			});
		} catch (error) {
			console.error(error);
		}
	}
	async getPortfolioReportAnalysis(sessionId, userMessage) {
		try {
			return await axios.post(`${apiUrl}/portfolio/portfolio-analysis-chat`, {
				email: JSON.parse(localStorage.getItem("userData")).email,
				sessionId: sessionId,
				userMessage: userMessage,
			});
		} catch (error) {
			console.log(error);
		}
	}

	async getCompanyDataFromScreener(symbol) {
		try {
			// Use the backend proxy endpoint with proper error handling
			const response = await axios.get(
				`${apiUrl}/proxy?symbol=${symbol}`
			);
			return response.data;
		} catch (error) {
			console.error("Error fetching company data:", error);
			throw new Error(
				error.response?.data?.message || "Failed to fetch company data"
			);
		}
	}
}

const server = new Server();

export default server;
