"use client"

import { useEffect, useState } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts"
import {
  ArrowUpRight,
  ArrowDownRight,
  AlertTriangle,
  BarChart2,
  PieChartIcon,
  Activity,
  RefreshCw,
  Loader,
  MessageSquare,
  X,
} from "lucide-react"
import PortfolioAnalysisVisualization from "./PortfolioAnalysis"
import Navbar from "./Navbar"
import AnalysisTab from "../components/AnalysisSidebar"
import ChatInterface from "../components/ChatInterface"
import server from "../api/Server"

const PortfolioPage = () => {
  const [loading, setLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [portfolioMetrics, setPortfolioMetrics] = useState(null)
  const [defaulterAnalysis, setDefaulterAnalysis] = useState(null)
  const [targetAnalysis, setTargetAnalysis] = useState(null)
  const [portfolioAnalysis, setPortfolioAnalysis] = useState(null)
  const [error, setError] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [showChat, setShowChat] = useState(false)
  const [chatClosed, setChatClosed] = useState(false)
  const [initialChatMessages, setInitialChatMessages] = useState([])
  const [sessionId, setSessionId] = useState()


  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#8dd1e1",
    "#a4de6c",
    "#d0ed57",
  ]

  useEffect(() => {
    localStorage.removeItem("chatMessages")
  }, [])
  
  const toggleChat = () => {
    const storedChat = localStorage.getItem("chatMessages")
    console.log("🚀 ~ useEffect ~ storedChat:", storedChat)
    if (storedChat) {
      try {
        const parsed = JSON.parse(storedChat)
        console.log("🚀 ~ useEffect ~ parsed:", parsed)
        if (Array.isArray(parsed) && parsed.length > 0) {
          setInitialChatMessages(parsed)
          setChatClosed(true)
        }
      } catch (err) {
        console.error("Error parsing stored chat:", err)
      }
    }
    // Just toggle visibility without clearing localStorage
    setShowChat(!showChat)
    if (!showChat) {
      setChatClosed(false)
    } else {
      setChatClosed(true)
    }
  }

  const fetchData = async () => {
    setLoading(true)
    setCurrentStep(0)
    setError(null)

    try {
      // Step 1: Get portfolio metrics
      setCurrentStep(1)
      const metricsResponse = await server.getPortfolioMetrics()
      console.log("🚀 ~ fetchData ~ metricsResponse:", metricsResponse)
      setPortfolioMetrics(metricsResponse.data)
      await delay(1000)

      // Step 2: Get defaulter rebalancing portfolio
      setCurrentStep(2)
      const defaulterResponse = await server.getDefaulterRebalancingPortfolio()
      setDefaulterAnalysis(defaulterResponse.data)
      await delay(1000)

      // Step 3: Get target rebalancing portfolio
      setCurrentStep(3)
      const targetResponse = await server.getTargetRebalancingPortfolio()
      setTargetAnalysis(targetResponse.data)
      await delay(1000)

      // Step 4: Get portfolio analysis
      setCurrentStep(4)
      const analysisResponse = await server.getPortfolioAnalysis()
    
      // const analysisResponse = PortfolioAnalysis
      setPortfolioAnalysis(analysisResponse.data)
      setSessionId(analysisResponse.data.data.session_id)
      await delay(1000)

      setCurrentStep(5) // Complete
      setSelectedTab(1) // Show portfolio metrics by default when analysis completes
    } catch (err) {
      console.error(err)
      setError("An error occurred while fetching portfolio data.")
    } finally {
      setLoading(false)
    }
  }

  // Format data for charts
  const formatWeightsForChart = (weights) => {
    if (!weights) return []
    return Object.entries(weights).map(([name, value]) => ({
      name,
      value: Number.parseFloat(value) * 100,
    }))
  }

  const formatCorrelationsForChart = (correlations) => {
    if (!correlations) return []
    return correlations.map((item) => ({
      name: `${item.pair[0]}-${item.pair[1]}`,
      value: Number.parseFloat(item.correlation),
    }))
  }

  // Render the appropriate content based on selected tab
  const renderTabContent = () => {
    switch (selectedTab) {
      case 1: // Portfolio Metrics
        return (
          <div className="bg-white shadow rounded-lg">
            <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
                <BarChart2 className="h-5 w-5 text-blue-500 mr-2" />
                Portfolio Metrics
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Current portfolio allocation and key performance indicators
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Portfolio Allocation */}
                <div className="h-80">
                  <h4 className="text-base font-medium text-gray-900 mb-4">Asset Allocation</h4>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={formatWeightsForChart(portfolioMetrics?.data?.metrics?.weights)}
                        cx="50%"
                        cy="45%"
                        labelLine={false}
                        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(1)}%`}
                        outerRadius={110}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {formatWeightsForChart(portfolioMetrics?.data?.metrics?.weights).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                {/* Key Metrics */}
                <div>
                  <h4 className="text-base font-medium text-gray-900 mb-4">Key Performance Indicators</h4>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-sm text-gray-500">Portfolio Return</p>
                      <p className="text-xl font-semibold flex items-center">
                        {(portfolioMetrics?.data?.metrics?.portfolioReturn * 100).toFixed(2)}%
                        {portfolioMetrics?.data?.metrics?.portfolioReturn > 0 ? (
                          <ArrowUpRight className="h-4 w-4 text-green-500 ml-1" />
                        ) : (
                          <ArrowDownRight className="h-4 w-4 text-red-500 ml-1" />
                        )}
                      </p>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-sm text-gray-500">Portfolio Beta</p>
                      <p className="text-xl font-semibold">
                        {portfolioMetrics?.data?.metrics?.portfolioBeta.toFixed(2)}
                      </p>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-sm text-gray-500">Volatility</p>
                      <p className="text-xl font-semibold">
                        {(portfolioMetrics?.data?.metrics?.portfolioVolatility * 100).toFixed(2)}%
                      </p>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-sm text-gray-500">Sharpe Ratio</p>
                      <p
                        className={`text-xl font-semibold ${portfolioMetrics?.data?.metrics?.sharpeRatio < 0 ? "text-red-500" : "text-green-500"}`}
                      >
                        {portfolioMetrics?.data?.metrics?.sharpeRatio.toFixed(2)}
                      </p>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-sm text-gray-500">Max Drawdown</p>
                      <p className="text-xl font-semibold text-red-500">
                        {(portfolioMetrics?.data?.metrics?.maxDrawdown * 100).toFixed(2)}%
                      </p>
                    </div>
                    <div className="bg-gray-50 rounded-lg p-4">
                      <p className="text-sm text-gray-500">Growth</p>
                      <p
                        className={`text-xl font-semibold ${portfolioMetrics?.data?.metrics?.growth < 0 ? "text-red-500" : "text-green-500"}`}
                      >
                        {(portfolioMetrics?.data?.metrics?.growth * 100).toFixed(2)}%
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      case 2: // Correlation Analysis
        return (
          <div className="bg-white shadow rounded-lg">
            <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
                <Activity className="h-5 w-5 text-blue-500 mr-2" />
                Correlation Analysis
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Asset correlations and diversification opportunities
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Positively Correlated Assets */}
                <div>
                  <h4 className="text-base font-medium text-gray-900 mb-4">Positively Correlated Assets</h4>
                  <div className="h-64">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={formatCorrelationsForChart(defaulterAnalysis?.data?.positivelyCorrelated)}
                        layout="vertical"
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" domain={[0, 1]} />
                        <YAxis dataKey="name" type="category" width={80} />
                        <Tooltip formatter={(value) => value.toFixed(2)} />
                        <Bar dataKey="value" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>

                {/* Near-Zero Correlated Assets */}
                <div>
                  <h4 className="text-base font-medium text-gray-900 mb-4">Near-Zero Correlated Assets</h4>
                  <div className="h-64">
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={formatCorrelationsForChart(defaulterAnalysis?.data?.nearZeroCorrelated)}
                        layout="vertical"
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" domain={[0, 0.2]} />
                        <YAxis dataKey="name" type="category" width={80} />
                        <Tooltip formatter={(value) => value.toFixed(2)} />
                        <Bar dataKey="value" fill="#82ca9d" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      case 3: // Target Portfolio Allocation
        return (
          <div className="bg-white shadow rounded-lg">
            <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900 flex items-center">
                <PieChartIcon className="h-5 w-5 text-blue-500 mr-2" />
                Target Portfolio Allocation
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Recommended asset allocation based on target analysis
              </p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={formatWeightsForChart(targetAnalysis?.data?.data)}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip formatter={(value) => `${value.toFixed(2)}%`} />
                    <Legend />
                    <Bar dataKey="value" name="Target Weight %" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        )
      case 4: // Portfolio Analysis
        return portfolioAnalysis ? <PortfolioAnalysisVisualization portfolioMetrics={portfolioMetrics} analysisResponse={portfolioAnalysis} /> : null
      default:
        return null
    }
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <header className="bg-white shadow-sm">
        <div className="max-w-full mx-auto px-4 py-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900 flex items-center">Portfolio Analysis Dashboard</h1>
          <div className="flex items-center gap-3">
            {currentStep >= 4 && !showChat && (
              <button
                onClick={toggleChat}
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <MessageSquare className="h-4 w-4 mr-2" />
                Chat with AI
              </button>
            )}
           <button
  onClick={fetchData}
  disabled={loading || showChat}
  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
    loading || showChat
      ? 'bg-gray-500 cursor-not-allowed'
      : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
  } disabled:opacity-50`}
>
  {loading ? (
    <>
      <Loader className="animate-spin -ml-1 mr-2 h-4 w-4" />
      Analyzing...
    </>
  ) : (
    <>
      <RefreshCw className="-ml-1 mr-2 h-4 w-4" />
      Analyze Portfolio
    </>
  )}
</button>

          </div>
        </div>
      </header>

      {/* Progress Indicator */}
      {loading && (
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="bg-white shadow rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-lg font-medium">Analysis Progress</h2>
              <span className="text-sm text-gray-500">Step {currentStep} of 4</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-blue-600 h-2.5 rounded-full transition-all duration-500"
                style={{ width: `${(currentStep / 4) * 100}%` }}
              ></div>
            </div>
            <div className="mt-2 text-sm text-gray-600">
              {currentStep === 1 && "Fetching portfolio metrics..."}
              {currentStep === 2 && "Analyzing correlation-based rebalancing..."}
              {currentStep === 3 && "Calculating target-based rebalancing..."}
              {currentStep === 4 && "Generating comprehensive portfolio analysis..."}
              {currentStep === 5 && "Analysis complete!"}
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="bg-red-50 border-l-4 border-red-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main Content */}
      <main className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {showChat ? (
          <div className="flex gap-6">
            {/* Left side - Analysis tabs */}
            <div className="w-2/5 bg-white shadow-md rounded-lg p-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-semibold">Analysis Sections</h2>
                <button onClick={toggleChat} className="p-1 rounded-full hover:bg-gray-100" aria-label="Close chat">
                  <X className="h-5 w-5 text-gray-500" />
                </button>
              </div>
              <div className="space-y-2">
                <AnalysisTab
                  title="Portfolio Metrics"
                  icon={<BarChart2 className="h-5 w-5" />}
                  isActive={selectedTab === 1}
                  isEnabled={currentStep >= 1}
                  onClick={() => setSelectedTab(1)}
                />
                <AnalysisTab
                  title="Correlation Analysis"
                  icon={<Activity className="h-5 w-5" />}
                  isActive={selectedTab === 2}
                  isEnabled={currentStep >= 2}
                  onClick={() => setSelectedTab(2)}
                />
                <AnalysisTab
                  title="Target Weights"
                  icon={<PieChartIcon className="h-5 w-5" />}
                  isActive={selectedTab === 3}
                  isEnabled={currentStep >= 3}
                  onClick={() => setSelectedTab(3)}
                />
                <AnalysisTab
                  title="Portfolio Analysis"
                  icon={<BarChart2 className="h-5 w-5" />}
                  isActive={selectedTab === 4}
                  isEnabled={currentStep >= 4}
                  onClick={() => setSelectedTab(4)}
                />
              </div>

              {/* Selected tab content */}
              <div className="mt-6 overflow-y-auto max-h-[calc(100vh-350px)]">{renderTabContent()}</div>
            </div>

            {/* Right side - Chat interface */}
            <div className="w-3/5 bg-white shadow-md rounded-lg">
            <ChatInterface isChatVisible={true} initialMessages={initialChatMessages} sessionId={sessionId} />
            </div>
          </div>
        ) : (
          <div className="flex gap-6">
            {/* Analysis sidebar */}
            <div className="w-64 bg-white shadow-md p-4 rounded-lg">
              <h2 className="text-lg font-semibold mb-4">Analysis Sections</h2>
              <div className="space-y-2">
                <AnalysisTab
                  title="Portfolio Metrics"
                  icon={<BarChart2 className="h-5 w-5" />}
                  isActive={selectedTab === 1}
                  isEnabled={currentStep >= 1}
                  onClick={() => setSelectedTab(1)}
                />
                <AnalysisTab
                  title="Correlation Analysis"
                  icon={<Activity className="h-5 w-5" />}
                  isActive={selectedTab === 2}
                  isEnabled={currentStep >= 2}
                  onClick={() => setSelectedTab(2)}
                />
                <AnalysisTab
                  title="Target Weights"
                  icon={<PieChartIcon className="h-5 w-5" />}
                  isActive={selectedTab === 3}
                  isEnabled={currentStep >= 3}
                  onClick={() => setSelectedTab(3)}
                />
                <AnalysisTab
                  title="Portfolio Analysis"
                  icon={<BarChart2 className="h-5 w-5" />}
                  isActive={selectedTab === 4}
                  isEnabled={currentStep >= 4}
                  onClick={() => setSelectedTab(4)}
                />
              </div>

              {currentStep >= 4 && (
                <div className="mt-8">
                  <button
                    onClick={toggleChat}
                    className="w-full flex items-center justify-center gap-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg transition-colors"
                  >
                    <MessageSquare className="h-4 w-4" />
                    Chat with AI
                  </button>
                </div>
              )}
            </div>

            {/* Main content area */}
            <div className="flex-1 overflow-y-auto max-h-[calc(100vh-200px)]">
              {chatClosed && (
                <div className="mb-4 bg-blue-50 border-l-4 border-blue-400 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <MessageSquare className="h-5 w-5 text-blue-400" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-blue-700">
                        Chat session in progress. Click "Chat with AI" to continue your conversation.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {!portfolioMetrics && !loading ? (
                <div className="text-center py-12 bg-white shadow rounded-lg">
                  <div className="mx-auto h-12 w-12 text-gray-400">
                    <BarChart2 className="h-12 w-12" />
                  </div>
                  <p className="mt-1 text-sm text-gray-500">Get started by analyzing your portfolio.</p>
                  <div className="mt-6">
                    <button
                      onClick={fetchData}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <RefreshCw className="-ml-1 mr-2 h-4 w-4" />
                      Analyze Portfolio
                    </button>
                  </div>
                </div>
              ) : (
                renderTabContent()
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  )
}

export default PortfolioPage

