"use client";

import { useRef } from "react";
import { useScroll } from "framer-motion";

import Header from "../components/landingpage/header.jsx";
import Hero from "../components/landingpage/hero.jsx";
import Features from "../components/landingpage/features.tsx";
import FAQItem from "../components/landingpage/testimonials";
import Contact from "../components/landingpage/contact";
import Footer from "../components/landingpage/footer.jsx";
import BlackWhiteVideoSection from "../components/landingpage/blackwhitevideosection.tsx"

export default function LandingPage() {
  const { scrollYProgress } = useScroll();

  // Create refs for each section
  const homeRef = useRef(null);
  const featuresRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  // Function to scroll to the section
  const scrollToSection = (sectionRef) => {
	console.log(sectionRef)
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="flex flex-col min-h-screen" style={{ background: "black" }}>
      <Header
        scrollToSection={scrollToSection} // Pass scroll function to Header
        homeRef={homeRef}
        featuresRef={featuresRef}
        aboutRef={aboutRef}
        contactRef={contactRef}
      />
      <div ref={homeRef}>
        <Hero />
      </div>
	  <div>
		<BlackWhiteVideoSection/>
	  </div>
      <div ref={featuresRef}>
        <Features scrollYProgress={scrollYProgress} />
      </div>
      <div ref={aboutRef}>
        <FAQItem />
      </div>
      <div ref={contactRef}>
        <Contact />
      </div>
      <Footer />
    </div>
  );
}
