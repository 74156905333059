"use client"
import React, {useState} from "react";
import { motion } from "framer-motion"
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import Modal from "./modal"; 

export default function Footer() {
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isTermsOfServiceOpen, setIsTermsOfServiceOpen] = useState(false);
  const [isCookiePolicyOpen, setIsCookiePolicyOpen] = useState(false);
  const footerLinks = [
    {
      title: "Quick Links",
      links: [
        { name: "Home", href: "#" },
        { name: "Features", href: "#features" },
        { name: "About", href: "#about" },
        { name: "Contact", href: "#contact" },
      ],
    },
    {
      title: "Legal",
      links: [
        { name: "Privacy Policy", href: "#", onClick: () => setIsPrivacyPolicyOpen(true) },
        { name: "Terms of Service", href: "#", onClick: () => setIsTermsOfServiceOpen(true) },
        { name: "Cookie Policy", href: "#", onClick: () => setIsCookiePolicyOpen(true) },
      ],
    },
    {
      title: "Company",
      links: [
        { name: "About Us", href: "#" },
        { name: "Careers", href: "#" },
        { name: "Blog", href: "#" },
      ],
    },
  ]

  const socialIcons = [
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/aift-ai/",
      icon: (
        <FaLinkedin/>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/aift_main/",
      icon: (
        <FaInstagram/>
      ),
    },
  ]

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  }

  return (
    <footer className="bg-black border-t border-white/10 pt-16 pb-8">
      <div className="container mx-auto px-4">
        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <motion.div variants={itemVariants}>
            <div className="mb-6">
              <img src="/icons/logoshadowed.png" alt="" style={{height: "70px"}}/>
              <p className="mt-2 text-white/70">AI-Powered Portfolio Optimization for Smarter Investing</p>
            </div>

            <div className="flex space-x-4">
              {socialIcons.map((social, index) => (
                <motion.a
                  key={index}
                  href={social.href}
                  className="text-white/70 hover:text-white transition-colors"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {social.icon}
                  <span className="sr-only">{social.name}</span>
                </motion.a>
              ))}
            </div>
          </motion.div>

          {footerLinks.map((group, groupIndex) => (
            <motion.div key={groupIndex} variants={itemVariants}>
              <h3 className="text-lg font-semibold mb-4 text-white">{group.title}</h3>
              <ul className="space-y-2">
                {group.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <button
                      onClick={link.onClick}
                      className="text-white/70 hover:text-white transition-colors"
                    >
                      {link.name}
                    </button>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          className="mt-16 pt-8 border-t border-white/10 text-center text-white/50 text-sm"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          viewport={{ once: true }}
        >
          © {new Date().getFullYear()} AIFT. All rights reserved.
        </motion.div>
      </div>
      {/* Modals */}
      <Modal isOpen={isPrivacyPolicyOpen} onClose={() => setIsPrivacyPolicyOpen(false)} title="Privacy Policy">
        <p>
Effective Date: 10-03-2025

At AIFT, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you visit our website (aift.com) and use our services.

1. Information We Collect
We may collect the following types of personal information when you use our website or services:

Personal Identification Information: Name, email address, phone number, and other details provided by you.
Usage Data: We automatically collect information about how you interact with our website, such as IP address, browser type, device type, and usage patterns.
Cookies and Tracking Technologies: We use cookies to enhance your experience on our website.
2. How We Use Your Information
We may use the collected information for the following purposes:

To provide, maintain, and improve our services
To communicate with you, including sending newsletters or promotional content
To personalize your experience and recommend relevant services
To comply with legal obligations
3. Data Sharing and Disclosure
We will not sell or rent your personal information to third parties. However, we may share your information with trusted third-party service providers who assist in the operation of our website and services, under strict confidentiality agreements.

4. Data Security
We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, alteration, or destruction. However, no data transmission method is 100% secure, and we cannot guarantee absolute security.

5. Your Rights
You have the right to access, correct, or delete your personal information. You may also object to the processing of your data in certain circumstances. To exercise your rights, please contact us at support@aift.com.

6. Changes to This Privacy Policy
We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting a notice on our website. Please review this policy periodically for any updates.</p>
      </Modal>
      
      <Modal isOpen={isTermsOfServiceOpen} onClose={() => setIsTermsOfServiceOpen(false)} title="Terms of Service">
        <p>
Effective Date: 10-03-2025

These Terms of Service ("Terms") govern your use of the AIFT website and services ("Services"). By accessing or using our website, you agree to comply with and be bound by these Terms.

1. Eligibility
You must be at least 18 years of age to use our website and services. By using our Services, you confirm that you meet this requirement.

2. Account Registration
To access certain features of our Services, you may need to create an account. You agree to provide accurate and up-to-date information when registering. You are responsible for maintaining the confidentiality of your account credentials.

3. Usage of Services
AIFT provides AI-powered portfolio optimization for smarter investing. You agree to use our Services only for lawful purposes and in accordance with these Terms.

4. Intellectual Property
The content, features, and functionality of the Services, including AI algorithms, designs, and logos, are the property of AIFT and are protected by intellectual property laws. You may not use, modify, or distribute any of our content without prior written consent.

5. Disclaimer of Warranties
Our Services are provided "as is" without any warranties, express or implied, including but not limited to the accuracy, reliability, or fitness for a particular purpose of the Services.

6. Limitation of Liability
AIFT shall not be liable for any indirect, incidental, special, or consequential damages arising out of or related to your use of the Services, even if we have been advised of the possibility of such damages.

7. Termination
We reserve the right to suspend or terminate your access to our Services if you violate these Terms or engage in unlawful conduct.

8. Governing Law
These Terms are governed by the laws of [Insert Jurisdiction]. Any disputes related to these Terms will be resolved in the courts located in [Insert Jurisdiction].

9. Changes to Terms
We may update these Terms from time to time. Any changes will be posted on this page, and the updated Terms will become effective immediately upon posting.</p>
      </Modal>

      <Modal isOpen={isCookiePolicyOpen} onClose={() => setIsCookiePolicyOpen(false)} title="Cookie Policy">
        <p>
Effective Date: 10-03-2025

This Cookie Policy explains how AIFT uses cookies and similar technologies to improve your experience while using our website.

1. What Are Cookies?
Cookies are small text files that are stored on your device when you visit a website. They allow the website to recognize your device and remember certain preferences or actions over time.

2. Types of Cookies We Use
We use the following types of cookies on our website:

Essential Cookies: These are necessary for the functioning of our website, such as to enable login or maintain session states.
Performance Cookies: These cookies help us understand how visitors interact with our website, such as tracking page views or click-through rates.
Functional Cookies: These cookies allow us to remember your preferences and provide personalized content.
Advertising Cookies: These cookies are used to serve ads relevant to your interests and track the effectiveness of our advertising campaigns.
3. How We Use Cookies
We use cookies for the following purposes:

To enhance the functionality of our website and improve your user experience
To analyze website traffic and usage patterns
To provide personalized content and advertising
4. Managing Cookies
Most browsers allow you to manage cookie settings, including blocking or deleting cookies. You can adjust your browser settings to refuse cookies or alert you when cookies are being sent. Please note that disabling cookies may affect the functionality of our website.

5. Third-Party Cookies
We may allow third-party service providers to use cookies on our website for purposes such as analytics or targeted advertising. These third parties are responsible for managing their cookies, and you should consult their privacy policies for more information.

6. Changes to This Cookie Policy
We may update this Cookie Policy from time to time. Any changes will be posted on this page, and the updated policy will become effective immediately upon posting.</p>
      </Modal>
    </footer>
  )
}

