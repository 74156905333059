import React, { useState } from "react";
import { FaEnvelope } from "react-icons/fa";

export default function Contact() {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});

	const [formStatus, setFormStatus] = useState(""); // To display submission status

	// Handle input changes
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	// Handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();

		// Simple validation
		if (!formData.name || !formData.email || !formData.message) {
			setFormStatus("Please fill in all fields.");
			setTimeout(() => {
				setFormStatus("")
			}, 3000);
			return;
		}

		const messageContent = `
      Name: ${formData.name}
      Email: ${formData.email}
      Message: ${formData.message}
    `;

    // Send email using mailto link
    const whatsappLink = `https://chat.whatsapp.com/KeTTmSKVOov3oTYlGtidcH?text=${encodeURIComponent(messageContent)}`;
    // Open the mailto link or WhatsApp link based on user choice (for now, let's just send the message via both)
    window.open(whatsappLink);

    setFormData({ name: "", email: "", message: "" }); // Reset the form
  };

	// Function to open the default email client
	const sendEmail = () => {
		window.open("mailto:tanvit@ai-ft.in");
	};

	return (
		<div className="min-h-[50vh] flex items-center justify-center p-4 font-sans bg-gray-50">
			<div className="w-full max-w-7xl bg-white text-gray-800 rounded-lg shadow-lg p-6 md:p-8 lg:grid lg:grid-cols-2 lg:gap-8">
				<div>
					<h1 className="text-4xl md:text-5xl font-bold">Let&apos;s Get in Touch</h1>
					<p className="text-sm text-gray-600 mt-3">
						Whether you have feedback, a question, or just want to connect, we'd love to hear from you! <br />
						Feel free to reach out and start a conversation.
					</p>
					<div className="mt-8">
						<h2 className="text-lg font-bold">Email Us</h2>
						<ul className="mt-1">
							<li>
								<button
									onClick={sendEmail}
									className="h-10 w-10 rounded-full flex items-center justify-center hover:bg-gray-100 transition-colors"
								>
									<FaEnvelope size={20} />
								</button>
							</li>
						</ul>
					</div>
				</div>
				<div className="mt-12 lg:mt-0 lg:pl-8 lg:border-l border-gray-200">
					<h2 className="text-lg font-bold">Send Us a Message</h2>
					<form onSubmit={handleSubmit} className="mt-3 space-y-4">
						<div>
							<input
								type="text"
								placeholder="Name"
								name="name"
								value={formData.name}
								onChange={handleInputChange}
								className="w-full rounded-lg p-3 bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-blue-600 border border-gray-200"
							/>
						</div>
						<div>
							<input
								type="email"
								placeholder="Email"
								name="email"
								value={formData.email}
								onChange={handleInputChange}
								className="w-full rounded-lg p-3 bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-blue-600 border border-gray-200"
							/>
						</div>
						<div>
							<textarea
								rows={4}
								placeholder="Message"
								name="message"
								value={formData.message}
								onChange={handleInputChange}
								className="w-full rounded-lg p-3 bg-gray-100 text-sm focus:outline-none focus:ring-2 focus:ring-blue-600 border border-gray-200 shadow-sm"
							></textarea>
						</div>
						<button
							type="submit"
							className="bg-blue-600 text-white border-none rounded-lg px-6 py-3 text-base cursor-pointer hover:bg-blue-700 transition-colors"
						>
							Send Message
						</button>
					</form>
					{formStatus && (
						<p className={`mt-4 text-sm ${formStatus.includes("success") ? "text-green-600" : "text-red-600"}`}>
							{formStatus}
						</p>
					)}
				</div>
			</div>
		</div>
	);
}
