"use client"

import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

export default function Hero() {
    const navigate = useNavigate()
    const handleLogin = () => {
        navigate("/login")
    }
    const handleOpenDemo = () => {
      window.open("https://calendly.com/tanvit-ai-ft/aift-discovery-and-demonstration", "_blank")
    }
  return (
    <section className="min-h-screen pt-24 pb-16 flex items-center">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Left Side */}
          <motion.div
            className="space-y-8"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.7 }}
          >
            <motion.div
              className="inline-block"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.2, duration: 0.5 }} style={{width: "66%", display: "flex", justifyContent: "center"}}
            >
              {/* <div className="text-4xl md:text-6xl font-bold tracking-tight text-white">AIFT</div> */}
              <img src="/icons/logoshadowed.png" style={{height: "260px"}} alt=""/>
            </motion.div>

            <motion.h1
              className="text-3xl md:text-5xl font-bold leading-tight text-white"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.6 }}
            >
              AI-Powered Portfolio Optimization for Smarter Investing
            </motion.h1>

            <motion.p
              className="text-lg md:text-xl text-white/70"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.6 }}
            >
              Turn Data into Actionable Financial Insights with our cutting-edge AI technology.
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
            >
              <motion.button
                className="px-8 py-3 bg-white text-black font-medium rounded-md hover:bg-white/90 transition-all"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }} onClick={handleLogin}
              >
                Try for Free
              </motion.button>

              <motion.button
                className="ml-4 px-8 py-3 border border-white/30 rounded-md text-white hover:bg-white/10 transition-all"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }} onClick={handleOpenDemo}
              >
                Book Demo
              </motion.button>
            </motion.div>
          </motion.div>
          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src="/icons/landingPage/landing page stock.png" style={{height: "28rem"}} alt=""/>
          </div>
        </div>
      </div>
    </section>
  )
}

