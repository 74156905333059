
export const PortfolioMetrics = {
    "code": 200,
    "data": {
        "metrics": {
            "weights": {
                "AAPL": 0.0825515947467167,
                "GOOGL": 0.05753595997498437,
                "MSFT": 0.075046904315197,
                "AMZN": 0.016260162601626018,
                "META": 0.150093808630394,
                "TSLA": 0.150093808630394,
                "JNJ": 0.039399624765478425,
                "KO": 0.0600375234521576,
                "WMT": 0.1938711694809256,
                "PG": 0.17510944340212634
            },
            "portfolioReturn": 0.0006012828359892657,
            "portfolioBeta": 1.053163232407755,
            "portfolioVolatility": 0.015930091425704875,
            "downsideDeviation": 0.05164656614834866,
            "rSquared": 0.00006624738236615801,
            "sharpeRatio": -2.7557103089299004,
            "sortinoRatio": -0.8499832697089069,
            "treynorRatio": -0.04168272857726807,
            "jensensAlpha": -0.11477369959351895,
            "calmarRatio": 0.0012560277556839822,
            "growth": -0.12289563106708767,
            "maxDrawdown": 0.4787177936699586,
            "covarianceMatrix": {
                "TSLA": {
                    "TSLA": 0.002512272156620189,
                    "GOOGL": 0.00033388641525036294,
                    "AMZN": 0.0003737145305422795,
                    "AAPL": 0.0008387590394867474,
                    "MSFT": 0.0003473093338449945,
                    "JNJ": 0.000027756673949782683,
                    "META": 0.0003775019289812402,
                    "WMT": 0.00007392090243841124,
                    "KO": 0.00007322592985509747,
                    "PG": 0.00004608193582931058
                },
                "GOOGL": {
                    "TSLA": 0.00033388641525036294,
                    "GOOGL": 0.0011335964848586536,
                    "AMZN": 0.0002830905158208387,
                    "AAPL": 0.00027276654459640765,
                    "MSFT": 0.00029037433675472906,
                    "JNJ": 0.00008280288496262115,
                    "META": 0.0003525124454834891,
                    "WMT": 0.00010673980767287901,
                    "KO": 0.00009644029943734869,
                    "PG": 0.00008817152655368585
                },
                "AMZN": {
                    "TSLA": 0.0003737145305422795,
                    "GOOGL": 0.0002830905158208387,
                    "AMZN": 0.0012209255456873392,
                    "AAPL": 0.0002477760922461978,
                    "MSFT": 0.0002944606829903747,
                    "JNJ": 0.00004333005987828227,
                    "META": 0.0003763757641142979,
                    "WMT": 0.0001005265424991238,
                    "KO": 0.00005668386270831811,
                    "PG": 0.00006627095011842857
                },
                "AAPL": {
                    "TSLA": 0.0008387590394867474,
                    "GOOGL": 0.00027276654459640765,
                    "AMZN": 0.0002477760922461978,
                    "AAPL": 0.0008176064012969625,
                    "MSFT": 0.0002791544614475403,
                    "JNJ": 0.00008330940997072017,
                    "META": 0.0003061928942086515,
                    "WMT": 0.00010422074640580946,
                    "KO": 0.00010584606928402839,
                    "PG": 0.00010134116823960448
                },
                "MSFT": {
                    "TSLA": 0.0003473093338449945,
                    "GOOGL": 0.00029037433675472906,
                    "AMZN": 0.0002944606829903747,
                    "AAPL": 0.0002791544614475403,
                    "MSFT": 0.0003573391109111495,
                    "JNJ": 0.00007808722781601026,
                    "META": 0.0003262880755994108,
                    "WMT": 0.0001006777628691615,
                    "KO": 0.00009357734805198119,
                    "PG": 0.00010421686397602155
                },
                "JNJ": {
                    "TSLA": 0.000027756673949782683,
                    "GOOGL": 0.00008280288496262115,
                    "AMZN": 0.00004333005987828227,
                    "AAPL": 0.00008330940997072017,
                    "MSFT": 0.00007808722781601026,
                    "JNJ": 0.00015102981615755707,
                    "META": 0.00006061637006544913,
                    "WMT": 0.00006785005586227181,
                    "KO": 0.00009114165067132902,
                    "PG": 0.00009667937169540353
                },
                "META": {
                    "TSLA": 0.0003775019289812402,
                    "GOOGL": 0.0003525124454834891,
                    "AMZN": 0.0003763757641142979,
                    "AAPL": 0.0003061928942086515,
                    "MSFT": 0.0003262880755994108,
                    "JNJ": 0.00006061637006544913,
                    "META": 0.0007910721835977783,
                    "WMT": 0.00009090633348051204,
                    "KO": 0.0000745236456495969,
                    "PG": 0.00008107696458365176
                },
                "WMT": {
                    "TSLA": 0.00007392090243841124,
                    "GOOGL": 0.00010673980767287901,
                    "AMZN": 0.0001005265424991238,
                    "AAPL": 0.00010422074640580946,
                    "MSFT": 0.0001006777628691615,
                    "JNJ": 0.00006785005586227181,
                    "META": 0.00009090633348051204,
                    "WMT": 0.0005500176800850223,
                    "KO": 0.00007201465291405114,
                    "PG": 0.00009283547102527127
                },
                "KO": {
                    "TSLA": 0.00007322592985509747,
                    "GOOGL": 0.00009644029943734869,
                    "AMZN": 0.00005668386270831811,
                    "AAPL": 0.00010584606928402839,
                    "MSFT": 0.00009357734805198119,
                    "JNJ": 0.00009114165067132902,
                    "META": 0.0000745236456495969,
                    "WMT": 0.00007201465291405114,
                    "KO": 0.0001666518857939479,
                    "PG": 0.0001076456108374302
                },
                "PG": {
                    "TSLA": 0.00004608193582931058,
                    "GOOGL": 0.00008817152655368585,
                    "AMZN": 0.00006627095011842857,
                    "AAPL": 0.00010134116823960448,
                    "MSFT": 0.00010421686397602155,
                    "JNJ": 0.00009667937169540353,
                    "META": 0.00008107696458365176,
                    "WMT": 0.00009283547102527127,
                    "KO": 0.0001076456108374302,
                    "PG": 0.0001761047154180067
                }
            }
        }
    },
    "error": false,
    "message": "Portfolio metrics generated successfully"
}

export const DefaulterRebalancer={
    "code":200,"data":{"newWeights":{"KO":0.0600375234521576,"PG":0.17510944340212634,"JNJ":0.039399624765478425,"WMT":0.1938711694809256,"AAPL":0.0825515947467167,"AMZN":0.016260162601626018,"META":0.150093808630394,"MSFT":0.075046904315197,"TSLA":0.150093808630394,"GOOGL":0.05753595997498437},"positivelyCorrelated":[{"pair":["KO","PG"],"correlation":0.628355929793098},{"pair":["META","MSFT"],"correlation":0.6136954019637153},{"pair":["JNJ","PG"],"correlation":0.592811991463211},{"pair":["AAPL","TSLA"],"correlation":0.5852369383091743},{"pair":["JNJ","KO"],"correlation":0.5744872926569213}],"negativelyCorrelated":[],"nearZeroCorrelated":[{"pair":["JNJ","TSLA"],"correlation":0.0450611980769676},{"pair":["TSLA","WMT"],"correlation":0.06288478677196337},{"pair":["PG","TSLA"],"correlation":0.06928063822538434},{"pair":["AMZN","JNJ"],"correlation":0.10090515456499134},{"pair":["KO","TSLA"],"correlation":0.11316872851785852}]},"error":false,"message":"Successful analysis"}


export const Reblancer={
    "code":200,"data":{"code":200,"data":{"AAPL":0.00001206142357063857,"AMZN":0.03140482629825539,"GOOGL":0.0010010602410362671,"JNJ":0.39981085293572066,"KO":0.26490647292749053,"META":0.03014450812326139,"MSFT":0.013601886484894686,"PG":0.17061708138472848,"TSLA":0.015445185417374848,"WMT":0.07305606476366724},"error":false,"message":"Successful analysis"},"error":false,"message":"Successful analysis"}

    export const PortfolioAnalysis = {
        "code": 200,
        "data": {
          "high_level_portfolio_summary": {
            "executive_summary": "The portfolio consists of 10 assets with a risk-neutral profile, indicating a balanced approach to risk and return. The portfolio's strengths include diversification across multiple sectors and a relatively high allocation to stable, blue-chip stocks like PG and WMT. However, the portfolio exhibits significant weaknesses, such as a negative growth rate (-12.29%), a high max drawdown (47.87%), and poor risk-adjusted performance metrics (e.g., Sharpe Ratio: -2.76, Sortino Ratio: -0.85). The portfolio's beta (1.05) indicates slightly higher market sensitivity, which could expose it to market volatility. Notable risk factors include over-allocation to high-volatility stocks like TSLA and META, which contribute to the portfolio's downside deviation and volatility.",
            "risk_analysis": "The portfolio's risk-return profile reveals a high beta (β = 1.05), indicating that the portfolio is slightly more volatile than the market. The portfolio volatility (σ = 1.59%) is moderate, but the downside deviation (5.16%) is concerning, reflecting significant potential losses during adverse market conditions. The Sharpe Ratio (-2.76) and Sortino Ratio (-0.85) indicate poor risk-adjusted returns, while the Calmar Ratio (0.0013) highlights the portfolio's inability to generate returns relative to its maximum drawdown. The Treynor Ratio (-0.0417) and Jensen's Alpha (-0.1148) further confirm the portfolio's underperformance relative to its risk exposure.",
            "asset_adjustments": {
              "trim": {
                "PG": "5%",
                "WMT": "7%",
                "META": "3%",
                "TSLA": "3%"
              },
              "increase": {
                "KO": "2%",
                "JNJ": "4%",
                "AAPL": "3%",
                "AMZN": "2%",
                "GOOGL": "3%"
              }
            },
            "stock_overview": {
              "KO": "Coca-Cola (KO) provides stability and consistent dividends, serving as a defensive asset in the portfolio.",
              "PG": "Procter & Gamble (PG) is a consumer goods giant, offering steady returns but currently over-allocated.",
              "JNJ": "Johnson & Johnson (JNJ) is a healthcare leader, providing diversification and stability.",
              "WMT": "Walmart (WMT) is a retail giant, offering consistent returns but currently over-allocated.",
              "AAPL": "Apple (AAPL) is a tech leader with strong growth potential, under-allocated in the portfolio.",
              "AMZN": "Amazon (AMZN) is an e-commerce and cloud computing leader, under-allocated despite its growth potential.",
              "META": "Meta Platforms (META) is a high-growth tech stock, contributing to portfolio volatility and over-allocated.",
              "MSFT": "Microsoft (MSFT) is a tech giant with balanced growth and stability, appropriately allocated.",
              "TSLA": "Tesla (TSLA) is a high-volatility growth stock, over-allocated and contributing to portfolio risk.",
              "GOOGL": "Alphabet (GOOGL) is a tech leader with strong fundamentals, under-allocated in the portfolio."
            },
            "calculation_explanations": {
              "formulas": {
                "Portfolio Variance (σ^2)": "ΣΣ(w_i * w_j * Cov(i, j)), where w_i and w_j are the weights of assets i and j, and Cov(i, j) is the covariance between assets i and j.",
                "Portfolio Beta (β)": "Σ(w_i * β_i), where w_i is the weight of asset i and β_i is the beta of asset i.",
                "Sharpe Ratio": "(Rp - Rf) / σp, where Rp is the portfolio return, Rf is the risk-free rate, and σp is the portfolio standard deviation.",
                "Sortino Ratio": "(Rp - Rf) / Downside Deviation, where Downside Deviation considers only negative deviations from the mean return.",
                "Calmar Ratio": "Rp / Max Drawdown, where Rp is the portfolio return and Max Drawdown is the maximum observed loss.",
                "Treynor Ratio": "(Rp - Rf) / βp, where βp is the portfolio beta.",
                "Jensen's Alpha": "Rp - [Rf + βp * (Rm - Rf)], where Rm is the market return."
              }
            }
          },
          "rebalancing_analysis": {
            "rebalance_summary": "Rebalancing was necessary due to deviations from the suggested 'risk-neutral' portfolio weights and the need to align the portfolio with the client's preferences and risk level. The current portfolio had significant overweights in certain assets (e.g., WMT, TSLA, META) and underweights in others (e.g., JNJ, KO), which could lead to suboptimal risk-return trade-offs. Additionally, the portfolio's correlation structure indicated opportunities to reduce risk by reallocating weights to less correlated assets.",
            "correlation_based_adjustments": {
              "trim": {
                "KO": "0.20486894947533293",
                "PG": "0.00449236201739786",
                "WMT": "0.12081510471725836",
                "AAPL": "0.08253953332314606",
                "META": "0.11994930050713261",
                "MSFT": "0.06144501783030231",
                "TSLA": "0.13464862321301915",
                "GOOGL": "0.0565348997339481"
              },
              "increase": {
                "JNJ": "0.36041122817024224",
                "AMZN": "0.01514466369662937"
              }
            },
            "target_based_adjustments": {
              "trim": {},
              "increase": {}
            },
            "impact_on_metrics": "The correlation-based rebalancing significantly reduced the portfolio's overall risk by reallocating weights to less correlated assets. This adjustment likely improved diversification, reduced volatility, and aligned the portfolio closer to a 'risk-neutral' profile. However, the reduction in weights for high-growth stocks like TSLA, META, and AAPL may slightly lower the portfolio's expected return. The absence of negatively correlated pairs in the portfolio before rebalancing suggests that the changes could enhance stability without introducing excessive risk.",
            "recommendations": "The correlation-based rebalancing approach is recommended as it successfully aligns the portfolio with a risk-neutral strategy while improving diversification. The target-based method was undefined, so no adjustments were made using that approach. It is advised to monitor the portfolio's performance post-rebalancing and reassess periodically to ensure alignment with the client's risk preferences and market conditions."
          },
          "actionable_next_steps": {
            "short_term_recommendations": "Given the negative growth trend (-12.29%) and poor portfolio performance metrics (e.g., Sharpe Ratio: -2.76, Sortino Ratio: -0.85), reduce exposure to high-volatility stocks like TSLA and META. Increase allocation to defensive sectors such as consumer staples (e.g., KO, PG) and healthcare (e.g., JNJ).",
            "long_term_strategies": "Focus on building a more balanced portfolio with a tilt towards quality growth stocks and dividend-paying assets. Gradually increase exposure to technology (e.g., MSFT, AAPL) as market conditions stabilize. Maintain a diversified approach to mitigate risk over the long term.",
            "hedging_opportunities": "Introduce fixed-income securities such as government bonds or bond ETFs to hedge against market volatility. Consider using options strategies like protective puts on high-volatility stocks (e.g., TSLA, META) to limit downside risk.",
            "alternative_investments": "Explore ETFs that track defensive sectors (e.g., Consumer Staples Select Sector SPDR Fund - XLP) or broad market indices (e.g., SPY) for diversification. Additionally, consider REITs or dividend-focused ETFs for steady income.",
            "asset_adjustments": {
              "trim": {
                "TSLA": "5%",
                "META": "5%",
                "AMZN": "3%",
                "GOOGL": "1%"
              },
              "increase": {
                "KO": "5%",
                "PG": "3%",
                "JNJ": "5%",
                "WMT": "2%",
                "MSFT": "2%"
              }
            },
            "market_risk_analysis": "The portfolio exhibits high volatility (15.93%) and a beta of 1.05, indicating sensitivity to market movements. The negative Sharpe and Sortino ratios highlight poor risk-adjusted returns. The high max drawdown (47.87%) suggests significant downside risk. A more defensive allocation is recommended to mitigate these risks."
          },
          "explanation_for_calculation_content": {
            "metric_explanations": {
              "portfolio_variance": "formula: σ² = ΣΣ (w_i * w_j * cov(i,j)) ... explanation: Portfolio variance is calculated by summing the weighted covariances of all asset pairs in the portfolio. Here, w_i and w_j represent the weights of assets i and j, and cov(i,j) is the covariance between the returns of assets i and j. This metric measures the overall risk of the portfolio.",
              "beta": "formula: β = Cov(portfolio, benchmark) / Var(benchmark) ... explanation: Beta measures the sensitivity of the portfolio's returns to the returns of a benchmark index. A beta of 1 indicates the portfolio moves in line with the benchmark, while a beta greater than 1 suggests higher volatility relative to the benchmark.",
              "volatility": "formula: σ = √(variance) ... explanation: Volatility is the square root of the portfolio variance and represents the standard deviation of the portfolio's returns. It indicates the degree of variation in returns over time.",
              "sharpe_ratio": "formula: Sharpe Ratio = (Rp - Rf) / σ ... explanation: The Sharpe Ratio measures the risk-adjusted return of the portfolio. Rp is the portfolio return, Rf is the risk-free rate, and σ is the portfolio's standard deviation. A higher Sharpe Ratio indicates better risk-adjusted performance.",
              "calmar_ratio": "formula: Calmar Ratio = Rp / Max Drawdown ... explanation: The Calmar Ratio evaluates risk-adjusted return by comparing the portfolio's return (Rp) to its maximum drawdown. It is useful for understanding performance during periods of significant losses.",
              "sortino_ratio": "formula: Sortino Ratio = (Rp - Rf) / Downside Deviation ... explanation: The Sortino Ratio is similar to the Sharpe Ratio but focuses only on downside risk (negative returns). Downside deviation measures the standard deviation of returns below a minimum acceptable return.",
              "treynor_ratio": "formula: Treynor Ratio = (Rp - Rf) / β ... explanation: The Treynor Ratio measures the portfolio's risk-adjusted return relative to its beta. It evaluates performance based on systematic risk (market risk)."
            },
            "rebalancing_explanations": {
              "correlation_based": "The correlation-based rebalancing method analyzes the relationships between assets in the portfolio. Highly correlated assets are identified to avoid over-concentration of risk, while near-zero or negatively correlated assets are preferred for diversification. Adjustments are made to reduce weights in highly correlated pairs and increase weights in less correlated or negatively correlated pairs. Recommended changes: { \"trim\": {\"META\": 0.150093808630394, \"TSLA\": 0.150093808630394}, \"increase\": {\"KO\": 0.0600375234521576, \"JNJ\": 0.039399624765478425} }",
              "target_based": "The target-based rebalancing method aligns the portfolio weights with predefined target allocations based on client preferences, risk tolerance, and performance metrics. Adjustments are made to bring the current weights closer to the target weights. Recommended changes: { \"trim\": {\"WMT\": 0.1938711694809256, \"AAPL\": 0.0825515947467167}, \"increase\": {\"KO\": 0.26490647292749053, \"JNJ\": 0.39981085293572066} }"
            },
            "asset_specific_explanations": {
              "KO": "Coca-Cola (KO) is a defensive stock with stable returns. Current allocation: 6.00%. Suggested increase to 26.49% to enhance stability and align with target-based strategy.",
              "PG": "Procter & Gamble (PG) is a consumer goods company. Current allocation: 17.51%. Slight reduction recommended in correlation-based rebalancing due to high correlation with KO.",
              "JNJ": "Johnson & Johnson (JNJ) is a healthcare stock. Current allocation: 3.94%. Significant increase to 39.98% suggested in target-based rebalancing to improve diversification and align with client preferences.",
              "WMT": "Walmart (WMT) is a retail stock. Current allocation: 19.39%. Reduction recommended to 7.31% in target-based rebalancing to reduce over-concentration.",
              "AAPL": "Apple (AAPL) is a technology stock. Current allocation: 8.26%. Reduction recommended to near-zero in target-based rebalancing to reduce exposure to volatile tech stocks.",
              "AMZN": "Amazon (AMZN) is an e-commerce and cloud computing company. Current allocation: 1.63%. Slight increase to 3.14% suggested in target-based rebalancing to align with client preferences.",
              "META": "Meta Platforms (META) is a social media and technology company. Current allocation: 15.01%. Reduction recommended in correlation-based rebalancing due to high correlation with MSFT.",
              "MSFT": "Microsoft (MSFT) is a technology and cloud computing company. Current allocation: 7.50%. Slight increase recommended in correlation-based rebalancing to improve diversification.",
              "TSLA": "Tesla (TSLA) is an electric vehicle and clean energy company. Current allocation: 15.01%. Reduction recommended in correlation-based rebalancing due to high correlation with AAPL.",
              "GOOGL": "Alphabet (GOOGL) is a technology and advertising company. Current allocation: 5.75%. Slight increase recommended in target-based rebalancing to align with client preferences."
            }
            
          },
          "session_id":"1741521700885-817167"
        },
        "error": false,
        "message": "Successful Report analysis"
      };
      